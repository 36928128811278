export const environment = {
  production: false,
  apiUrl: 'https://gc-station-service-r27bib7kla-uc.a.run.app',
  googleMapsApiKey: 'AIzaSyAl1zq-SILKDIE6At73pgf7R-vOofLc0L0',
  firebase: {
    apiKey: 'AIzaSyAl1zq-SILKDIE6At73pgf7R-vOofLc0L0',
    authDomain: 'gitcharger-dev.firebaseapp.com',
    projectId: 'gitcharger-dev',
    storageBucket: 'gitcharger-dev.appspot.com',
    messagingSenderId: '44284727423',
    appId: '1:44284727423:web:1405dbc542396494d3af4a',
    measurementId: 'G-9BNEMHF97W',
  },

  api: {
    stations_gql: 'https://stations-graphql-r27bib7kla-uc.a.run.app/graphql',
    user: 'https://user-service-r27bib7kla-uc.a.run.app/v1',
    file: 'https://file-service-r27bib7kla-uc.a.run.app',
    station: 'https://gc-station-service-r27bib7kla-uc.a.run.app',
    feedback: 'https://feedback-service-r27bib7kla-uc.a.run.app/api/v1',
    rewards: 'https://reward-service-r27bib7kla-uc.a.run.app/api',
    search: 'https://gc-search-service-r27bib7kla-uc.a.run.app'
  },

  stripe: {
    publishableKey: 'pk_test_51QJlaFIjdJ8yolDDBd9cWR3QHMmNjhuENF7lKCKIAsdpctoNaVcAAuZQV9M6noJDmWQsI0kmTE4y7vxSchv4C9F500aNCxZvMv',
    secretKey: 'sk_test_51QJlaFIjdJ8yolDDxrg7Xb4U2uTfexxGXfjArKi7xkNnKUT0Swxt44nQgoEBkne3kc6d9wZSHzXviETi6rLqrWpc00bMpuQhvP',
  },
};
