import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ConnectorType, Station } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { CONNECTOR_ICONS } from '@app/shared/utils/consts';
import { getPlugs } from '@app/shared/utils/station';
import { firstValueFrom } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';

interface ConnectorStatus {
  AVAILABLE: number;
  OUT_OF_ORDER: number;
  chargeKwh: number;
  connector: ConnectorType;
  icon?: string;
}
type ConnectorMap = Map<number, ConnectorStatus>;

@Component({
  selector: 'app-connector-list',
  standalone: true,
  imports: [CommonModule, SimplebarAngularModule],
  templateUrl: './connector-list.component.html',
  styleUrl: './connector-list.component.scss',
})
export class ConnectorListComponent implements OnInit {
  @Input() station: Station = null;
  @Input() onlyText?: boolean = false;
  plugsCount = null;
  connectors: WritableSignal<ConnectorStatus[]> = signal([]);

  constructor(private stationService: StationService) {}

  ngOnInit(): void {
    this.plugsCount = getPlugs(this.station?.chargers);

    this.connectorsByType().then((data) => {
      this.connectors.set(
        Array.from(data.entries()).map(([, value]) => ({
          ...value,
          icon:
            CONNECTOR_ICONS[
              value.connector.name.replace(/[^a-zA-Z0-9]/g, '')
            ] ?? CONNECTOR_ICONS[''],
        })),
      );
    });
  }

  async connectorsByType() {
    if (this.station?.chargers?.length < 1) return new Map();

    const apiConnectorTypes = await firstValueFrom(
      this.stationService.getConnectorTypes(),
    );

    const connectorTypes: ConnectorMap = new Map();
    this.station?.chargers.forEach((charger) => {
      charger.connectors.forEach((connector) => {
        if (!connectorTypes.has(connector.connectorTypeId)) {
          const _conn = apiConnectorTypes.find(
            (c) =>
              String(c.connectorTypeId) === String(connector.connectorTypeId),
          );
          connectorTypes.set(connector.connectorTypeId, {
            AVAILABLE: 0,
            OUT_OF_ORDER: 0,
            connector: { ..._conn, name: _conn?.name! },
            chargeKwh: connector?.chargeKwh > -1 ? connector?.chargeKwh : 0,
          });
        }

        if (connector.status === 'AVAILABLE') {
          connectorTypes.set(connector.connectorTypeId, {
            ...connectorTypes.get(connector.connectorTypeId)!,
            AVAILABLE:
              (connectorTypes?.get(connector?.connectorTypeId)?.AVAILABLE ??
                0) + 1,
          });
        }

        if (connector.status === 'OUT_OF_ORDER') {
          connectorTypes.set(connector.connectorTypeId, {
            ...connectorTypes.get(connector.connectorTypeId)!,
            OUT_OF_ORDER:
              (connectorTypes?.get(connector?.connectorTypeId)?.OUT_OF_ORDER ??
                0) + 1,
          });
        }

        if (
          connector.chargeKwh &&
          connector.chargeKwh >
            (connectorTypes?.get(connector.connectorTypeId)?.chargeKwh ?? 0)
        ) {
          connectorTypes.set(connector.connectorTypeId, {
            ...connectorTypes?.get(connector.connectorTypeId)!,
            chargeKwh: connector.chargeKwh,
          });
        }
      });
    });

    return connectorTypes;
  }
}
