<div class="app-connector-list">
  <p>
    <span [class]="plugsCount?.available > 0 ? 'text-green' : 'text-red'">{{
      plugsCount?.available
    }}</span
    >/<span [class]="plugsCount?.total === 0 ? 'text-red' : ''">{{
      plugsCount?.total
    }}</span>
    Plugs available
  </p>

  @if (!onlyText) {
    <ngx-simplebar [options]="{ autoHide: true }">
      <div class="connectors">
        <div class="connector-item" *ngFor="let connector of connectors()">
          <img [src]="connector.icon" />
          <div class="content">
            <h4>{{ connector.connector.name }}</h4>
            <p [style.opacity]="connector.chargeKwh > 0 ? '1' : '0'">
              {{ connector.chargeKwh ?? 0 }}KW
            </p>
          </div>
        </div>
      </div>
    </ngx-simplebar>
  }
</div>
