<mat-card class="station-preview-container">
  <mat-card-header class="station-preview-header">
    <div>
      <h4 class="station-name">{{ place?.displayName?.text }}</h4>
      <p class="location-type" *ngIf="place?.primaryTypeDisplayName?.text">
        {{ place?.primaryTypeDisplayName?.text }}
      </p>
    </div>

    <div class="station-rating" *ngIf="place?.rating">
      <span class="rating">{{ place.rating }}</span>
      <div class="icons">
        @for (i of [1, 2, 3, 4, 5]; track i) {
          <mat-icon [class]="i <= place.rating ? 'active' : ''">
            star
          </mat-icon>
        }
      </div>
      <span class="total" *ngIf="place.userRatingCount"
        >({{ place.userRatingCount }})</span
      >
    </div>
  </mat-card-header>

  <mat-card-content>
    <ul class="info-list">
      <li>
        <mat-icon>map</mat-icon>
        <span>{{ place?.shortFormattedAddress }}</span>
      </li>
      <li>
        <mat-icon>location_on</mat-icon>
        <app-skeleton style="width: 185px; height: 19px" *ngIf="!distance"></app-skeleton>
        <span *ngIf="distance">{{ distance.mi }} mi away | {{ distance.duration }} away</span>
      </li>
      <li>
        <mat-icon>schedule</mat-icon>
        <span
          class="{{
            place?.currentOpeningHours?.openNow ? 'text-green' : 'text-red'
          }}"
          >{{
            place?.currentOpeningHours?.openNow ? "Open now" : "Closed"
          }}</span
        >
      </li>
    </ul>
  </mat-card-content>

  <mat-card-footer>
    <mat-card-actions class="buttons">
      <a
        mat-icon-button
        href="{{ place?.websiteUri }}"
        target="_blank"
        *ngIf="place?.websiteUri"
      >
        <mat-icon>link</mat-icon>
      </a>
      <button mat-icon-button (click)="openDirection()">
        <mat-icon>directions</mat-icon>
      </button>
      <a
        mat-icon-button
        href="tel:{{ place?.internationalPhoneNumber }}"
        *ngIf="place?.internationalPhoneNumber"
      >
        <mat-icon>call</mat-icon>
      </a>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
