<div class="hero-container">
    <div class="limit-width-row md-hero-container">
        <div class="hero-content">
            <h1>A smarter way to charge</h1>
            <p>You are the one who decides what map you will follow! GitCharger is a navigation and knowledge hub service used worldwide to plan trips and find EV stations with friends and communities.</p>
            <div class="download-links">
              <a
                href="https://play.google.com/store/apps/details?id=com.gitcharger.app"
                target="_blank"
              >
                <img src="assets/playstore.svg" />
              </a>
              <a
                href="https://apps.apple.com/us/app/gitcharger/id6450578675"
                target="_blank"
              >
                <img src="assets/appstore.svg" />
              </a>
            </div>
        </div>
        <div class="hero-image">
            <div class="made-for-container">
                <img src="assets/about/made_for.svg" alt="Made for IOS and Android devices">
            </div>
            <img src="assets/about/hero.png" alt="Hero image">
        </div>
        <div class="donuts">
            <div class="hide-overflow">
                <div class="donut-small">
                    <div class="donut-small-hole"></div>
                </div>
                <div class="donut-large">
                    <div class="donut-large-hole"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="benefits-section container">
    <div class="section-intro">
        <aside>A small peek into Gitcharger</aside>
        <h2>Electricity and GitCharger can transform people’s lives</h2>
    </div>
    <div class="benefit">
        <div class="benefit-image">
            <img src="assets/about/benefit1.png" alt="Image displaying app features that make it easy to find preferred EV stations" width="785">
        </div>
        <div class="benefit-text right">
            <h3>Find preferred EV stations near you</h3>
            <p>With a robust network of information, combined with the electric community, obtain a unique experience that allows for optimal EV charging.</p>
            <ul>
                <li><p>Filter out low quality charging stations</p></li>
                <li><p>Detailed charging station information</p></li>
                <li><p>Live status update on charging station</p></li>
            </ul>
        </div>
    </div>
    <div class="benefit left">
        <div class="benefit-text left">
            <h3>Electric vehicle road trips without the hassle</h3>
            <p>Make travel easy using our Trip Planner. GitCharger will provide charging routes to make your trips hassle free.</p>
            <ul>
                <li><p>Accurate range estimates based on your vehicle</p></li>
                <li><p>Trip planner that caters to your amenity needs</p></li>
                <li><p>Route planning that considers station busyness</p></li>
            </ul>
        </div>
        <div class="benefit-image">
            <img src="assets/about/benefit2.png" alt="Image displaying app features that make it easy to find preferred EV stations" width="785">
        </div>
    </div>
    <div class="benefit">
        <div class="benefit-image">
            <img src="assets/about/benefit3.png" alt="Image displaying app features that make it easy to find preferred EV stations" width="785">
        </div>
        <div class="benefit-text right">
            <h3>Be part of a diverse electric community</h3>
            <p>Join and communicate with your fellow local EV owners, share tips and tricks, and learn the latest EV trends.</p>
            <ul>
                <li><p>Less fragmented community</p></li>
                <li><p>Better focus on sharing information</p></li>
                <li><p>Stronger electric support network</p></li>
            </ul>
        </div>
    </div>
    <div class="benefit left">
        <div class="benefit-text left">
            <h3>Voting and rating system</h3>
            <p>Create a profile for you and your EV to provide personal and custom experience. Let your voice be heard.</p>
            <ul>
                <li><p>Contribute and vote for new station</p></li>
                <li><p>Rate your experience after a station use</p></li>
                <li><p>Bring awareness towards broken station</p></li>
            </ul>
        </div>
        <div class="benefit-image">
            <img src="assets/about/benefit4.png" alt="Image displaying app features that make it easy to find preferred EV stations" width="785">
        </div>
    </div>
</div>

<div class="features-section">
  <div class="container">
    <div class="section-intro">
        <aside>Features we provide</aside>
        <h2>Amazing features that will electrify your EV experience</h2>
    </div>
    <div class="row">
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/pin.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Heat Map</h5>
            <p>View a concentration of where users like yourself are charging, traveling, posting, and updating to.</p>
        </div>
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/share.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Location Sharing</h5>
            <p>Stay connected with your friends across the world, see what adventures and vehicles they’re taking.</p>
        </div>
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/trip.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Trip Planner</h5>
            <p>Speed up your trip planning experience with filters that consider amenities as an option while on the road.</p>
        </div>
    </div>
    <div class="row">
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/cart.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Store</h5>
            <p>Find and purchase accessories and chargers based on vehicles you drive using our store filters.</p>
        </div>
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/bell.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Smart Notifications</h5>
            <p>Stay informed through notifications based on where you have been, plan to visit, or your surrounding area.</p>
        </div>
        <div class="feature">
            <div class="icon-container">
                <div class="icon">
                    <img src="assets/about/icons/hub.svg" alt="Image displaying app features that make it easy to find preferred EV stations" height="35" width="35">
                </div>
            </div>
            <h5>Knowledge Hub</h5>
            <p>Get tailored news on a wide range of electric topics or onboard someone unfamiliar to electric vehicles.</p>
        </div>
    </div>
  </div>
</div>

<div class="cta-section container">
    <div class="section-intro">
        <h2>Take the next step to make a difference</h2>
    </div>
    <div class="ctahero-container">
        <img src="assets/about/footerhero.png" alt="Mobile screens depicting the main features of this app">
    </div>
</div>

<div class="bottom-hero"></div>
