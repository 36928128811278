import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';

interface WalletData {
  id: number;
  location: string;
  sessionTime: string;
  delivered: string;
  chargingCost: string;
  date: string;
  status: string;
}

const SAMPLE_DATA: WalletData[] = [
  {
    id: 34021,
    location: '24117 96th St, Live Oak, FL 32060, US',
    sessionTime: '12 mins',
    delivered: '15.9 kWh',
    chargingCost: '$5.22',
    date: 'Nov 3, 2024 2:22PM',
    status: 'Canceled',
  },
  // Add more rows as needed
];

@Component({
  selector: 'app-wallet-table',
  templateUrl: './wallet-table.component.html',
  styleUrls: ['./wallet-table.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
  ],
})
export class WalletTableComponent implements OnInit {
  displayedColumns: string[] = ['select', 'id', 'location', 'sessionTime', 'delivered', 'chargingCost', 'date', 'status'];
  dataSource = new MatTableDataSource<WalletData>(SAMPLE_DATA);
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectAll() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  toggleSelection(element: any) {
    this.selection.toggle(element);
  }
}
