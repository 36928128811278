import { Component, Input, OnChanges, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-gc-image',
  standalone: true,
  imports: [],
  templateUrl: './gc-image.component.html',
  styleUrl: './gc-image.component.scss',
})
export class GcImageComponent implements OnChanges {
  @Input() src = '';
  @Input() alt = '';
  @Input() style = '';
  @Input() noHeader = false;
  imageUrl = signal('');

  private http = inject(HttpClient);

  constructor(private authService: AuthService) {}

  async ngOnChanges() {
    this.imageUrl.set(this.src);

    if (!this.noHeader) {
      const token = await firstValueFrom(this.authService.getIdToken());
      if (!token) {
        return;
      }

      this.http
        .get(this.src, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' })
        .subscribe((blob) => {
          this.imageUrl.set(window.URL.createObjectURL(blob));
        });
    }
  }
}
