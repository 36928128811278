import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from "@app/services/auth.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { SocialLoginComponent } from "@app/components/auth/social-login/social-login.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { RegisterLoginComponent } from '../register-login/register-login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { finalize } from 'rxjs';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

@Component({
  selector: 'app-verify',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule, SocialLoginComponent, ReactiveFormsModule, MatProgressSpinnerModule],
  templateUrl: './verify.component.html',
  styleUrl: './verify.component.scss'
})
export class VerifyComponent {
  fb = inject(FormBuilder)
  authService = inject(AuthService)
  verifyForm: FormGroup;
  dialogRef = inject(MatDialogRef<RegisterLoginComponent>);
  isLoading = signal(false);
  isResendButtonDisabled = signal(false);
  remainingTime = 0;
  formattedTime = signal('00:00');

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.verifyForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
    dayjs.extend(duration);
  }

  verify(): void {
    if (this.verifyForm.valid) {
      this.isLoading.set(true);

      const userEmail = this.authService.userSignal()?.email;
      const { code } = this.verifyForm.value;
      this.authService.verifyCode(userEmail, code)
        .pipe(
          finalize(() => {
            this.isLoading.set(false);
          })
        )
        .subscribe({
          next: () => {
            // NOTE: we're not getting the updated user just instantly after the server response
            // maybe there's an delay in updating it, so we're reloading the whole page for now
            /* this.authService.user$.subscribe(async (user) => {
              this.authService.userSignal.set(user);
            }); */
            window.location.reload();

            this.snackBar.open('User verified', undefined, { duration: 3000 });
            this.dialogRef.close();
          },
          error: () => {
            this.snackBar.open('Invalid verification code', undefined, { duration: 3000 });
          },
        });
    }
  }

  resendVerificationCode() {
    this.snackBar.open('User verification code sent', undefined, { duration: 3000 });
    this.authService.resendVerificationCode(this.authService.userSignal()?.email).subscribe();
    this.disableResendButtonForTwoMinutes();
  }

  disableResendButtonForTwoMinutes() {
    this.isResendButtonDisabled.set(true);

    // Set remaining time to 120 seconds (2 minutes)
    this.remainingTime = 120;
    this.formattedTime.set(dayjs.duration(this.remainingTime, 'seconds').format('mm:ss'));

    const intervalId = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
        this.formattedTime.set(dayjs.duration(this.remainingTime, 'seconds').format('mm:ss'));
      } else {
        // Enable button and clear the interval when time is up
        this.isResendButtonDisabled.set(false);
        clearInterval(intervalId);
      }
    }, 1000);
  }

  onVerify(event: Event) {
    event.preventDefault();
    this.verify();
  }

  openSignInForm() {
    this.dialogRef.close();
    this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'login',
      },
    });
  }
}
