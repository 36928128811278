import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [RouterLink, MatButtonModule, MatIconModule],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent implements OnInit {
  constructor(private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.snackBar.open('Page not found', undefined, {
      duration: 3000,
    });
  }

  goBack() {
    if (window.history.length > 2) {
      window.history.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
