import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { ReportDialogComponent } from '@app/components/report-dialog/report-dialog.component';
import { AuthService } from '@app/services/auth.service';
import { CategoryType } from '@app/services/feedback.interface';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { UserService } from '@app/services/user.service';
import { VehicleInterface } from '@app/services/vehicle.interface';
import { getDefaultVehicle } from '@app/shared/utils/station';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CommonModule,
    GcImageComponent,
    RouterLink,
    MatIconModule,
    RouterLinkActive,
    RouterOutlet,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTabsModule,
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  @Input() userId: string;
  user: PublicMetadataInterface & { uid: string } = undefined;
  defaultVehicle: VehicleInterface = null;
  userPhoto = null;

  @ViewChild('nav', { static: false }) nav!: ElementRef;

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.authService.whenNotAuthenticated(() => {
      this.router.navigate(['/']);
    });
  }

  ngOnInit(): void {
    this.userPhoto = this.userService.getUserPhoto(this.userId);
    this.userService.getUserById(this.userId).pipe(
      catchError(() => {
        //TODO handle unauthorized
        this.snackBar.open('User not found', undefined, { duration: 3000 });
        this.router.navigate(['/not-found']);
        return of(null);
      }),
    ).subscribe((user) => {
      this.user = {
        uid: user.uid,
        displayName: user.displayName,
        userPreferences: {
          vehicles: user.userMetadata?.userPreferences['vehicles']
        },
        country: user.userMetadata?.country
      }
      this.defaultVehicle = getDefaultVehicle(user.userMetadata?.userPreferences['vehicles']);
    });
  }

  openReportDialog() {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      data: {
        categoryType: CategoryType.USER,
        categoryId: this.userId,
      },
    });
  }

  scrollNav(distance: number): void {
    this.nav.nativeElement.scrollBy({ left: distance, behavior: 'smooth' });
  }
}
