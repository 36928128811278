import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Place } from "./help.interface";

@Injectable({
  providedIn: "root",
})
export class HelpService {
  constructor(private http: HttpClient) {}

  getPlaces(props: {
    latitude: number;
    longitude: number;
    radius?: number;
    keywords: string[];
    maxResultCount?: number;
  }) {
    return this.http.post<{
      places: Place[];
    }>(
      `https://places.googleapis.com/v1/places:searchNearby`,
      {
        includedTypes: props.keywords,
        maxResultCount: props.maxResultCount ?? 20,
        rankPreference: "DISTANCE",
        locationRestriction: {
          circle: {
            center: {
              latitude: props.latitude,
              longitude: props.longitude,
            },
            radius: 10_000,
          },
        },
      },
      {
        headers: {
          "X-Goog-Api-Key": environment.googleMapsApiKey,
          "X-Goog-FieldMask": "places.location,places.displayName,places.primaryTypeDisplayName,places.currentOpeningHours,places.formattedAddress,places.internationalPhoneNumber,places.id,places.shortFormattedAddress,places.rating,places.userRatingCount,places.websiteUri,places.priceLevel,places.parkingOptions",
        },
      },
    );
  }
}
