<div class="address-preview-container">
  <div class="address-preview-header">
    <div>
      <h4 class="address-name">{{ address?.address?.areaName1 ?? address?.address?.formattedStreetAddress }}</h4>
      <p class="full-address">{{ address?.address?.formattedAddress }}</p>
    </div>

    <button mat-icon-button (click)="navigate()">
      <mat-icon>directions</mat-icon>
    </button>
  </div>
</div>
