import { AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatDivider } from '@angular/material/divider';
import { UserDropdownComponent } from '@app/components/user-dropdown/user-dropdown.component';
import { AuthService } from '@app/services/auth.service';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkDialogComponent } from '@app/components/bookmark-dialog/bookmark-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDivider,
    UserDropdownComponent,
    MatMenuTrigger,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnDestroy {
  private _bookmarksDialog = inject(MatDialog);

  links = [
    [
      {
        path: '/',
        title: 'Map',
        icon: 'map',
        target: '_self',
      },
      {
        path: '/navigation',
        title: 'Navigation',
        icon: 'route',
      },
      // {
      //   path: '/contribute',
      //   title: 'Contribute',
      //   icon: 'add_circle',
      // },
      // {
      //   path: '/friends',
      //   title: 'Friends',
      //   icon: 'group',
      // },
      {
        title: 'Bookmarks',
        icon: 'bookmark',
        onClick: () => {
          document.querySelector('mat-sidenav').classList.remove('opened');
          this._bookmarksDialog.open(BookmarkDialogComponent, {
            maxWidth: '700px',
            width: '700px',
            height: '845px'
          });
        },
        loginRequired: true,
      },
      { divider: true },
    ],
    // [
    //   {
    //     path: '/articles',
    //     title: 'Articles',
    //     icon: 'description',
    //   },
    //   {
    //     path: '/store',
    //     title: 'Store',
    //     icon: 'local_mall',
    //   },
    // ],
    // [
    //   {
    //     path: '/leaderboard',
    //     title: 'Leaderboard',
    //     icon: 'workspace_premium',
    //   },
    //   {
    //     path: '/company',
    //     title: 'Company',
    //     icon: 'apartment',
    //   },
    // ],
    [
      {
        path: '/contact',
        title: 'Contact Us',
        icon: 'mail',
        target: '_blank',
      },
      {
        path: '/terms',
        title: 'Terms of Use',
        icon: 'article',
        target: '_blank',
      },
      {
        path: '/privacy',
        title: 'Privacy Policy',
        icon: 'policy',
        target: '_blank',
      },
      {
        path: '/about',
        title: 'About Us',
        icon: 'apartment',
        target: '_blank',
      },
      { divider: true },
    ],
  ];
  showFullNav = false;

  constructor(public authService: AuthService, private router: Router) {
    router.events.subscribe(() => {
      document.querySelector('mat-sidenav').classList.remove('opened');
    });
  }

  ngOnInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    console.log('destroying sidebar');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth <= 768) {
      this.showFullNav = true;
    } else {
      this.showFullNav = false;
    }
  }

  toggleNav() {
    this.showFullNav = !this.showFullNav;
  }
}
