<div class="app-change-password">
  <h1 class="account-page-title">Change password</h1>

  <form
    [formGroup]="initialFormGroup"
    (ngSubmit)="onInitialSubmit()"
    *ngIf="status() === 'initial'"
  >
    <mat-form-field appearance="outline">
      <mat-label>Current password</mat-label>
      <input
        matInput
        type="password"
        formControlName="currentPassword"
        required
      />
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Next
      </button>
    </div>
  </form>

  <form
    [formGroup]="verifyFormGroup"
    (ngSubmit)="onVerifySubmit()"
    *ngIf="status() === 'verify'"
  >
    <mat-form-field appearance="outline">
      <mat-label>Verification code</mat-label>
      <input matInput formControlName="code" required />
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Next
      </button>
    </div>
  </form>

  <form
    [formGroup]="resetFormGroup"
    (ngSubmit)="onResetSubmit()"
    *ngIf="status() === 'reset'"
    class="reset-password-form"
  >
    <mat-form-field appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput type="password" formControlName="newPassword" required />
      <mat-error *ngIf="resetFormGroup.controls['newPassword'].hasError('required')">Password is required</mat-error>
      <mat-error *ngIf="resetFormGroup.controls['newPassword'].hasError('minlength')">Password must be at least 8 characters</mat-error>
      <mat-error *ngIf="resetFormGroup.controls['newPassword'].hasError('customPassword')">Password must contain an uppercase letter, a special character</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirm password</mat-label>
      <input matInput type="password" formControlName="confirmPassword" required />
      <mat-error *ngIf="resetFormGroup.controls['confirmPassword'].hasError('required')">Password is required</mat-error>
      <mat-error *ngIf="resetFormGroup.controls['confirmPassword'].hasError('minlength')">Password must be at least 8 characters</mat-error>
      <mat-error *ngIf="resetFormGroup.controls['confirmPassword'].hasError('customPassword')">Password must contain an uppercase letter, a special character</mat-error>
    </mat-form-field>
    <div>
      <button [disabled]="isUpdating()" mat-flat-button matStepperNext>
        Reset password
      </button>
    </div>
  </form>
</div>
