import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { SearchControlService } from '@app/pages/map/controls/services/search-control.service';
import { GeocodeResult } from '@app/services/model/search';

@Component({
  selector: 'app-address-preview',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  templateUrl: './address-preview.component.html',
  styleUrl: './address-preview.component.scss'
})
export class AddressPreviewComponent {
  @Input() address: GeocodeResult;

  constructor(private searchControlService: SearchControlService, private router: Router) {}

  navigate() {
    this.searchControlService.autoFillDestination.set(this.address?.address?.formattedAddress);
    this.router.navigate(['/navigation']);
  }
}
