import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { Station } from '@app/services/station.interface';
import { WEEKDAYS } from '@app/shared/utils/consts';
import { formatTime } from '@app/shared/utils';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { ConnectorListComponent } from '../connector-list/connector-list.component';
import { AmenitiesListComponent } from '../amenities-list/amenities-list.component';
import dayjs from 'dayjs';
import { StationDetailsService } from '@app/pages/station-details/station-details.service';

@Component({
  selector: 'app-station-info',
  standalone: true,
  imports: [
    LeafletModule,
    MatDividerModule,
    MatIconModule,
    CommonModule,
    ConnectorListComponent,
    AmenitiesListComponent,
  ],
  templateUrl: './station-info.component.html',
  styleUrl: './station-info.component.scss',
})
export class StationInfoComponent implements OnInit {
  @Input() station: Station = null;

  readonly hoursOpenState = signal(false);
  readonly costsOpenState = signal(false);
  openHoursText = signal('');
  costsText = signal('');

  formatTime = formatTime;
  weekdays = WEEKDAYS;
  showAllAmenities = false;
  lastModifiedDate = '';
  connectorStatus = '';
  stationHours = this.station?.hours?.dayHours;

  constructor(private stationDetailsService: StationDetailsService) {}

  ngOnInit(): void {
    this.lastModifiedDate = dayjs(this.station?.updatedTs).format('MM.DD.YYYY');
    this.getOpenHoursText();
    this.getCostsText();

    const isAvailable = this.station?.chargers.some(charger =>
      charger.connectors.some(connector => connector.status === "AVAILABLE")
    );
    this.connectorStatus = isAvailable ? "Available" : "Unknown";

    const filteredHours = {};
    Object.keys(this.station?.hours?.dayHours).forEach(day => {
      const filtered = this.station?.hours?.dayHours[day].filter(period => period?.open && period?.close);
      if (filtered.length > 0) {
        filteredHours[day] = filtered;
      }
    });
    this.stationHours = filteredHours;
  }

  get displayedAmenities() {
    return this.showAllAmenities
      ? this?.station?.amenities
      : this?.station?.amenities?.slice(0, 6);
  }

  toggleAmenities() {
    this.showAllAmenities = !this.showAllAmenities;
  }

  convertLowerCase(string: string) {
    return String(string).toLowerCase();
  }

  getOpenHoursText() {
    this.openHoursText.set(this.stationDetailsService.getOpenHoursText(this.station));
  }

  getCostsText() {
    this.costsText.set(this.stationDetailsService.getCostsText(this.station));
  }

  hasDayHoursEntries() {
    return !!this.stationHours && Object.values(this.stationHours).some(day => day && day.length > 0);
  }

  hasCostsEntries() {
    return !!this.station.stationPricingHours.pricingHours && Object.values(this.station.stationPricingHours.pricingHours).some(day => day && day.length > 0);
  }
}
