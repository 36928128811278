import { Component, inject, Input, OnChanges, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { SkeletonComponent } from '@app/components/skeleton/skeleton.component';
import { StationImage } from '@app/services/station.interface';
import { Router, RouterLink } from '@angular/router';
import { UserService } from '@app/services/user.service';
import dayjs from 'dayjs';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { getDefaultVehicle } from '@app/shared/utils/station';
import { VehicleInterface } from '@app/services/vehicle.interface';
import { AuthService } from '@app/services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { StationService } from '@app/services/station.service';
import { ReportDialogComponent } from '@app/components/report-dialog/report-dialog.component';
import { CategoryType } from '@app/services/feedback.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-station-image',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    GcImageComponent,
    SkeletonComponent,
    RouterLink,
    MatMenuModule,
  ],
  templateUrl: './station-image.component.html',
  styleUrl: './station-image.component.scss',
})
export class StationImageComponent implements OnInit, OnChanges {
  @Input() image: string = null;
  isUserdataLoading = signal(false);
  stationImage: StationImage = null;
  userData: PublicMetadataInterface = null;
  defaultVehicle: VehicleInterface = null;
  uploadedDate = null;
  getUserPhoto = null;
  userSignal = inject(AuthService).userSignal;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private userService: UserService,
    private stationService: StationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getUserPhoto = this.userService.getUserPhoto;
  }

  ngOnChanges(): void {
    try {
      this.stationImage = JSON.parse(atob(decodeURIComponent(this.image)));
      this.uploadedDate = dayjs(this.stationImage.uploadedDate).format(
        'DD MMMM, YYYY'
      );

      this.isUserdataLoading.set(true);
      this.userService
        .getMetadataById(this.stationImage.uploadedBy)
        .subscribe((data) => {
          this.userData = data;
          this.defaultVehicle = getDefaultVehicle(data.userPreferences?.vehicles);
          this.isUserdataLoading.set(false);
        });
    } catch {
      this.stationImage = null;
      this.snackBar.open('Image not found', undefined, {
        duration: 3000,
      });
    }
  }

  goBack() {
    this.router.navigate(['/station', this.stationImage.stationId]);
  }

  delete() {
    if (this.userSignal().uid !== this.stationImage.uploadedBy) return;

    this.snackBar.open('Image deleted', undefined, { duration: 3000 });
    document.querySelector(`[data-station-image="${this.stationImage.blobName}"]`)?.remove();
    this.goBack();
    this.stationService.deleteStationImage(this.userSignal().uid, this.stationImage.blobName).subscribe();
  }

  openReportDialog() {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      data: {
        categoryType: CategoryType.IMAGE,
        categoryId: this.stationImage.blobName,
      },
    });
  }
}
