import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { RegisterLoginComponent } from "@app/components/auth/register-login/register-login.component";
import { AuthService } from "@app/services/auth.service";
import { RewardsService } from "@app/services/rewards.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-assign-reward",
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: "./assign-reward.component.html",
  styleUrl: "./assign-reward.component.scss",
})
export class AssignRewardComponent implements OnInit {
  @Input() eventId: string;

  constructor(
    private authService: AuthService,
    private rewardsService: RewardsService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.assignEvent();

    // open login dialog if user is not authenticated
    this.authService.whenNotAuthenticated(() => {
      const dialogRef = this.dialog.open(RegisterLoginComponent, {
        maxWidth: "1000px",
        data: {
          type: "login",
        },
        disableClose: true, // can't close the dialog
      });

      dialogRef.afterClosed().subscribe(() => {
        this.assignEvent();
      });
    });
  }

  async assignEvent() {
    const user = await firstValueFrom(this.authService.getUserObject());
    if (!this.authService.isAuthenticated(user.permission)) return;

    const eventId = Number(this.eventId);
    const userId = this.authService.userSignal()?.uid;

    this.rewardsService.assignReward(eventId, userId).subscribe(() => {
      this.snackBar.open("Event assigned successfully", undefined, {
        duration: 3000,
      });
      this.router.navigate(["/profile", userId]);
    });
  }
}
