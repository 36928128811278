import { CommonModule } from '@angular/common';
import { Component, Inject, inject, signal } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioButton } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/services/auth.service';
import { StationService } from '@app/services/station.service';
import { UserService } from '@app/services/user.service';
import { REPORT_TYPES } from '@app/shared/data/report';
import { catchError, finalize, firstValueFrom } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';

interface StartConnectorDialogData {
  stationId: number;
  chargerConnectorId: number;
  type: "START" | "WAIT";
}

@Component({
  selector: 'app-start-connector-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    MatRadioButton,
    SimplebarAngularModule,
    MatSelectModule,
  ],
  templateUrl: './start-connector-dialog.component.html',
  styleUrl: './start-connector-dialog.component.scss'
})
export class StartConnectorDialogComponent {
  CHARGING_DURATIONS = [
    "I will be here for 10 minutes",
    "I will be here for 15 minutes",
    "I will be here for 20 minutes",
    "I will be here for 25 minutes",
    "I will be here for 30 minutes",
  ];

  dialogRef = inject(MatDialogRef<StartConnectorDialogComponent>);
  private _formBuilder = inject(FormBuilder);
  formGroup = this._formBuilder.group({
    duration: ['', Validators.required],
    notes: [''],
  });
  isLoading = signal(false);
  REPORT_TYPES = REPORT_TYPES;
  listExpanded = signal(false);

  keepOriginalOrder = () => 0;

  constructor(
    private snackBar: MatSnackBar,
    private stationService: StationService,
    private userService: UserService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA)
    public props: StartConnectorDialogData,
  ) {
    this.authService.whenNotAuthenticated(() => {
      this.dialogRef.close();
    });
  }

  close() {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (!this.formGroup.valid) return;

    const formData = this.formGroup.value;

    this.isLoading.set(true);

    const user = this.authService.userSignal()
    const userMetadata = await firstValueFrom(this.userService.getMetadataById(user.uid));
    const defaultVehicle = userMetadata?.userPreferences?.vehicles?.find((vehicle) => vehicle.default);

    if (!defaultVehicle) {
      this.snackBar.open('You have no vehicles yet.', undefined, {
        duration: 3000,
      });
      this.isLoading.set(false);
      return;
    }

    this.stationService.createConnectorActivity({
      userId: user.uid,
      userName: user.displayName,
      stationId: this.props.stationId,
      chargerConnectorId: this.props.chargerConnectorId,
      activityType: this.props.type,
      vehicleModel: `${defaultVehicle.model} ${defaultVehicle.make}`,
      duration: formData.duration,
      notes: formData.notes,
    })
      .pipe(
        catchError(() => {
          this.snackBar.open('Error submitting form', undefined, {
            duration: 3000,
          });
          throw new Error('Error submitting form');
        }),
        finalize(() => {
          this.isLoading.set(false);
        }),
      )
      .subscribe(() => {
        this.snackBar.open(this.props.type === "START" ? "Your session has started" : "Waiting to start session", undefined, { duration: 3000 });
        this.dialogRef.close();
      });
  }
}
