import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Pipe, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { debounceTime, firstValueFrom, Subject, window } from 'rxjs';
import { signal } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationRequest, RouteResponse, SearchResult, Stop } from '@app/services/model/search';
import { SearchService } from '@app/services/search.service';
import { Station } from '@app/services/model/search';
import { SearchControlService } from '../map/controls/services/search-control.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { prettyAddress } from '@app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { getUserCoordinates } from '@app/shared/utils/location';

interface Waypoint {
  name: string;
  latLng?: number[];
  placeId?: string;
  searchResults?: SearchResult[];
  stations?: Station[];
  station?: Station;
}

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [
    CommonModule,
    SimplebarAngularModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatAutocompleteModule,
    MatDividerModule,
    RouterLink,
    MatProgressSpinnerModule,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  animations: [
    trigger('slideAnimation', [
      state('expanded', style({
        left: '0'
      })),
      state('collapsed', style({
        left: 'var(--width)'
      })),
      transition('expanded <=> collapsed', animate('0.2s ease-in-out'))
    ])
  ]
})
export class NavigationComponent implements OnInit, OnDestroy, AfterViewInit {
  private searchSubject = new Subject<Waypoint>();
  private readonly debounceTimeMs = 300;

  @ViewChild('autoFocus') autoFocusField: ElementRef;

  prettyAddress = prettyAddress;

  waypoints = signal<Waypoint[]>([{ name: '' }, { name: '' }]);
  routeData = signal<RouteResponse | null>(null);

  isLoading = signal(false);

  constructor(private searchService: SearchService, public searchControlService: SearchControlService, private router: Router, private snackBar: MatSnackBar, private route: ActivatedRoute) {}

  ngOnInit() {
    this.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue) => {
        this.performSearch(searchValue);
      });

    // Auto fill user location
    (async () => {
      const userLocation = await getUserCoordinates();
      if (userLocation) {
        this.waypoints.update(waypoints => {
          waypoints[0] = {
            name: 'Your location',
            latLng: [userLocation.latitude, userLocation.longitude],
          };
          return waypoints;
        });
      }
    })();

    // Auto fill the destination location, if it's provided in the query params
    (async () => {
      const destination = this.searchControlService.autoFillDestination();
      if (destination) {
        this.isLoading.set(true);

        let data = null;
        if (destination.startsWith('place_id/')) {
          const [_, placeId] = destination.split('/');
          const geocode = await firstValueFrom(this.searchService.geocode(placeId, 'place'));

          if (geocode) {
            data = {
              address: geocode.address,
              placeId: placeId,
              name: geocode.address.formattedAddress,
              isStation: false,
            };
          }
        } else {
          const origin = this.searchControlService.getMapCenterCoords();
          const results = await firstValueFrom(this.searchService.autocomplete(
            destination,
            origin,
          ))
          data = results[0];
        }

        if (data) {
          this.addressSelected(1, data);
          // remove the last empty waypoint
          if (this.waypoints().at(-1)?.name === '') {
            this.waypoints.update(waypoints => waypoints.slice(0, -1));
          }
        } else {
          this.snackBar.open('Location not found', undefined, { duration: 3000 });
        }

        this.isLoading.set(false);
      }
    })();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.autoFocusField.nativeElement.focus();
    }, 100)
  }

  ngOnDestroy(): void {
    this.waypoints.set([{ name: '' }, { name: '' }]);
    this.routeData.set(null);

    this.searchControlService.clearNavigationPlanner();
    this.searchControlService.enableStationPins();
    this.searchControlService.autoFillDestination.set(null);
    this.searchControlService.isNavigationCollapsed = false;

    this.searchControlService.navigationPlannerLayers = [];
    this.searchControlService.stationPreviewLayers = [];
    this.searchControlService.stationPreviewIds = [];
  }

  addWaypoint() {
    this.waypoints.set([
      ...this.waypoints().slice(0, -1),
      { name: '' },
      this.waypoints().at(-1)!, // Get the last waypoint
    ]);
  }

  updateWaypoint(index: number, event) {
    const updatedWaypoints = [...this.waypoints()];
    updatedWaypoints[index].name = event.target.value;

    this.waypoints.set(updatedWaypoints);
    this.searchSubject.next(updatedWaypoints[index]);
  }

  switchRoute() {
    const origin = this.waypoints().at(0);
    const destination = this.waypoints().at(-1);

    this.waypoints.update(waypoints => {
      waypoints[0] = destination;
      waypoints[waypoints.length - 1] = origin;

      return waypoints;
    });

    this.performNavigation();
  }

  removeWaypoint(index: number) {
    const updatedWaypoints = this.waypoints().filter((_, i) => i !== index);
    this.waypoints.set(updatedWaypoints);

    this.performNavigation();
  }

  removeWaypointStation(index: number, stationId: number) {
    const updatedWaypoints = this.waypoints().map((waypoint) => {
      waypoint.stations = waypoint.stations.filter(station => station.id !== stationId);
      return waypoint;
    });
    this.waypoints.set(updatedWaypoints);

    this.performNavigation();
  }

  drop(event: CdkDragDrop<Waypoint[]>) {
    const updatedWaypoints = [...this.waypoints()];
    moveItemInArray(updatedWaypoints, event.previousIndex, event.currentIndex);
    this.waypoints.set(updatedWaypoints);

    this.performNavigation();
  }

  private performSearch(waypoint: Waypoint) {
    if (!waypoint.name) {
      const updatedWaypoints = [...this.waypoints()];
      updatedWaypoints[this.waypoints().indexOf(waypoint)].searchResults = [];
      this.waypoints.set(updatedWaypoints);
      return;
    }

    const origin = this.searchControlService.getMapCenterCoords();
    const search$ = this.searchService.autocomplete(
      waypoint.name,
      origin,
    );

    search$.subscribe((results) => {
      const updatedWaypoints = [...this.waypoints()];
      updatedWaypoints[this.waypoints().indexOf(waypoint)].searchResults = results;

      this.waypoints.set(updatedWaypoints);
    });
  }

  addressSelected(waypointIndex: number, data: any) {
    const searchResult = JSON.parse(data.value);
    this.waypoints.update(waypoints => {
      waypoints[waypointIndex].name = searchResult.address.formattedAddress;
      waypoints[waypointIndex].placeId = searchResult.placeId;
      waypoints[waypointIndex].station = null;

      return waypoints;
    });

    // Add new waypoint once the destination is selected
    if (this.waypoints().at(-1).placeId) {
      this.waypoints.update(waypoints => [...waypoints, { name: '' }])
    }

    this.performNavigation();
  }

  convertJSON(searchResult: SearchResult) {
    return JSON.stringify(searchResult);
  }

  async performNavigation() {
    const allRoutes = this.waypoints().filter((value) => value.placeId || value.station || value.latLng);
    if (allRoutes.length < 2) {
      return;
    }

    const origin = allRoutes.at(0)!;
    const destination = allRoutes.at(-1)!;
    const waypoints = allRoutes.slice(1, -1);

    const payload: NavigationRequest = {
      origin: this.convertWaypointToPayload(origin),
      destination: this.convertWaypointToPayload(destination),
      intermediates: waypoints.map(this.convertWaypointToPayload),
    }

    this.searchService.route(payload).subscribe((results) => {
      const route = results?.routes?.[0];
      if (route) {
        this.routeData.set(route);
        this.searchControlService.updateNavigationPlanner(route);
        this.searchControlService.showNavigationPins(route, (legIndex, stationIndex) => {
          this.waypoints.update(waypoints => {
            const station = route.legs[legIndex].stations[stationIndex];
            if (waypoints?.find((item) => item.station?.id === station.id)) {
              waypoints = waypoints.filter(s => s.station?.id !== station.id);
            } else {
              const legWaypoint = waypoints.filter((item) => !item.station).find((_, index) => index === legIndex);
              const newIndex = waypoints.findIndex((waypoint) => waypoint?.placeId === legWaypoint?.placeId);

              waypoints.splice(newIndex + 1, 0, {
                name: station.name,
                station,
              });
            }
            return waypoints;
          })
        });
      } else {
        this.routeData.set(null);
        this.searchControlService.clearNavigationPlanner();
        this.snackBar.open('No route found', undefined, { duration: 3000 });
      }
    });
  }

  convertWaypointToPayload(waypoint: Waypoint): Stop {
    if (waypoint.latLng) {
      return {
        location: {
          latLng: {
            latitude: waypoint.latLng[0],
            longitude: waypoint.latLng[1],
          },
          heading: 0,
        },
      }
    }

    if (waypoint.station && !waypoint.placeId) {
      return {
        location: {
          station: waypoint.station,
          latLng: waypoint.station.location,
          heading: 0,
        },
        address: waypoint.placeId ? undefined : waypoint?.name,
      }
    }

    return {
      place: waypoint?.placeId ? {
        id: waypoint.placeId,
      } : undefined,
      address: waypoint.placeId ? undefined : waypoint?.name,
    }
  }

  goBack() {
    this.router.navigate(['/']);
  }

  togglePanel() {
    this.searchControlService.isNavigationCollapsed = !this.searchControlService.isNavigationCollapsed;
  }

  clearWaypoint(index: number, event: MouseEvent) {
    const waypoint = this.waypoints().at(index);
    if (waypoint.name === 'Your location') {
      this.waypoints.update(waypoints => {
        waypoints[index].name = '';
        waypoints[index].latLng = null;
        return waypoints;
      });
      (event.target as any).value = '';
    }
  }
}
