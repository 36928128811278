<div class="app-station-details">
  <ngx-simplebar [options]="{ autoHide: true }" style="height: 100%">
    <mat-toolbar>
      <button mat-icon-button aria-label="Back button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </mat-toolbar>

    @if (loadingStates().station) {
      <div class="station-content">Loading the station...</div>
    } @else if (!station) {
      <div class="station-content">Station not found.</div>
    } @else {
      <div>
        @if (stationImage !== null) {
          <div class="station-image">
            @if (stationImage === "") {
              <app-skeleton
                style="
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                "
              ></app-skeleton>
            }
            <app-gc-image [src]="stationImage"></app-gc-image>
          </div>
        }

        <div class="station-content">
          <h2 class="station-title">
            <img [src]="markerUrl" alt="marker">
            {{ station.name }}
          </h2>

          <div class="station-contacts" style="margin: 10px 0">
            <div class="station-contact-item">
              <span>{{ fullAddress }}</span>
            </div>
          </div>

          @if (loadingStates().ratings) {
            <app-skeleton style="width: 152px; height: 20px"></app-skeleton>
          } @else {
            <div class="station-rating">
              <span class="rating">{{ ratings.rating }}</span>
              <div class="icons">
                @for (i of [1, 2, 3, 4, 5]; track i) {
                  <mat-icon [class]="i <= ratings.rating ? 'active' : ''">
                    star
                  </mat-icon>
                }
              </div>
              <span class="total">({{ ratings.count }})</span>
            </div>
          }

          <div class="station-contacts">
            <div class="action-buttons">
              @if (station?.phone) {
                <a href="tel:{{ station.phone }}" mat-icon-button>
                  <mat-icon>phone</mat-icon>
                </a>
              }
              @if (station?.website) {
                <a
                  href="//{{ station.website }}"
                  target="_blank"
                  mat-icon-button
                >
                  <mat-icon>language</mat-icon>
                </a>
              }
              @if (station?.email) {
                <a href="mailto:{{ station.email }}" mat-icon-button>
                  <mat-icon>mail</mat-icon>
                </a>
              }
              <mat-divider
                [style.display]="
                  station?.phone || station?.website || station?.email
                    ? 'inline-block'
                    : 'none'
                "
                [vertical]="true"
              ></mat-divider>
              @if (authService.isAuthenticated()) {
                <button (click)="toggleBookmark()" [class]="isBookmarked() ? 'active' : ''" mat-icon-button>
                  <mat-icon>bookmark</mat-icon>
                </button>
                <button (click)="openReportDialog()" mat-icon-button>
                  <mat-icon>report</mat-icon>
                </button>
              }
              <button mat-icon-button (click)="share()">
                <mat-icon>share</mat-icon>
              </button>
              <button mat-icon-button (click)="fillNavigation()">
                <mat-icon>directions</mat-icon>
              </button>
            </div>
          </div>

          <mat-tab-group dynamicHeight>
            <mat-tab label="Station info">
              <app-station-info [station]="station"></app-station-info>
            </mat-tab>
            <mat-tab label="Connectors">
              <app-connectors-tab [station]="station"></app-connectors-tab>
            </mat-tab>
            <mat-tab label="Ratings">
              <ng-template matTabContent>
                <app-ratings-tab [station]="station"></app-ratings-tab>
              </ng-template>
            </mat-tab>
            <mat-tab label="Images">
              <ng-template matTabContent>
                <app-images-tab [station]="station"></app-images-tab>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    }
  </ngx-simplebar>
</div>

<router-outlet></router-outlet>
