import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportDialogComponent } from '@app/components/report-dialog/report-dialog.component';
import { CategoryType } from '@app/services/feedback.interface';
import { EventInterface } from '@app/services/rewards.interface';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-reward-dialog',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatDividerModule, CommonModule, QRCodeModule],
  templateUrl: './reward-dialog.component.html',
  styleUrls: ['./reward-dialog.component.scss'],
})
export class RewardDialogComponent implements OnInit {
  public dialogRef = inject(MatDialogRef<RewardDialogComponent>);
  public data: EventInterface = inject(MAT_DIALOG_DATA);
  showFullText = signal(true);
  description = signal('');
  defaultLength = 500;
  showQrCode = false;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.toggleFullText();
  }

  toggleFullText() {
    this.showFullText.set(!this.showFullText());

    if (this.showFullText()) {
      this.description.set(this.data.eventDescription);
    } else {
      this.description.set(
        this.data.eventDescription.length > this.defaultLength
          ? this.data.eventDescription.slice(0, this.defaultLength) + '...'
          : this.data.eventDescription,
      );
    }
  }

  close() {
    if (this.showQrCode) {
      this.toggleQrCode();
      return;
    }

    if (!this.dialogRef.disableClose) {
      this.dialogRef.close();
    }
  }

  openReportDialog() {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      data: {
        // TODO: needs to replace these data with the actual data
        categoryType: CategoryType.EVENT,
        categoryId: this.data.id,
      },
    });
  }

  share() {
    navigator.clipboard.writeText(this.getShareLink());
    this.snackBar.open('Link copied to clipboard', undefined, {
      duration: 3000,
    });
  }

  getShareLink() {
    return window.location.origin + '/event/' + this.data.id;
  }

  toggleQrCode() {
    this.showQrCode = !this.showQrCode;
  }
}
