<div class="app-report-dialog">
  <div class="header">
    <h3>{{ props.type === "START" ? "Start charging" : "Waiting" }}</h3>
    <button mat-icon-button class="close-button" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <ngx-simplebar [options]="{ autoHide: true }" style="max-height: 560px;">
    <div class="content">
      <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>How long will you be here?</mat-label>
            <mat-select formControlName="duration" required>
              <mat-option [value]="duration" *ngFor="let duration of CHARGING_DURATIONS">{{duration}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Do you have any notes for other EV owners?</mat-label>
          <textarea formControlName="notes" matInput rows="7" placeholder="Type here..."></textarea>
        </mat-form-field>

        <p class="description" style="margin-bottom: 0">
          Note: You can use this box only while you are charging. It will
          disappear once you leave the charging station.
        </p>

        <div style="display: flex; justify-content: flex-end;">
          <button [disabled]="isLoading() || formGroup.invalid" mat-flat-button matStepperNext>
            Done
          </button>
        </div>
      </form>
    </div>
  </ngx-simplebar>
</div>
