import {Component, Inject, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {RegisterComponent} from "@app/components/auth/register/register.component";
import {LoginComponent} from "@app/components/auth/login/login.component";
import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import { VerifyComponent } from '../verify/verify.component';

type ModalType = 'login' | 'verify' | 'register';

@Component({
  selector: 'app-register-login',
  standalone: true,
  imports: [MatDialogModule, MatButton, MatFormField, MatInput, MatLabel, RegisterComponent, LoginComponent, NgIf, MatIcon, MatToolbarModule, MatIconButton, VerifyComponent],
  templateUrl: './register-login.component.html',
  styleUrl: './register-login.component.scss'
})
export class RegisterLoginComponent {
  type: ModalType = 'login';
  dialogRef = inject(MatDialogRef<RegisterLoginComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { type: ModalType }) {
    this.type = data.type;
  }

  toggleForm() {
    this.type = this.type === 'login' ? 'register' : 'login';
  }

  closeDialog() {
    if (this.dialogRef.disableClose) return;

    this.dialogRef.close();
  }
}
