<div class="app-reward-dialog">
  <button mat-icon-button class="close-button" (click)="close()" *ngIf="!dialogRef.disableClose">
    <mat-icon>close</mat-icon>
  </button>

  <div class="station-content {{ showQrCode ? 'hide' : '' }}">
    <h2 class="station-title">
      {{ data.title }}
    </h2>

    <div class="station-contacts" style="margin: 10px 0">
      <div class="station-contact-item">
        <span>{{ data.locationAddress }}</span>
      </div>
    </div>

    <div class="station-contacts">
      <div class="action-buttons">
        <a [href]="data.eventUrl" target="_blank" mat-icon-button>
          <mat-icon>link</mat-icon>
        </a>
        <!-- <button mat-icon-button>
          <mat-icon>bookmark</mat-icon>
        </button>
        <button mat-icon-button (click)="openReportDialog()">
          <mat-icon>report</mat-icon>
        </button> -->
        <button mat-icon-button (click)="share()">
          <mat-icon>share</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleQrCode()">
          <mat-icon>qr_code</mat-icon>
        </button>
      </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="section general-details">
      <h3 class="section-title">General details</h3>
      <p>
        {{ description() }}
      </p>

      @if (data.eventDescription?.length > defaultLength) {
        <button (click)="toggleFullText()" class="toggle-button">
          {{ showFullText() ? "Show Less" : "Show More" }}
        </button>
      }
    </div>
  </div>

  <div class="station-content qr-code-dialog" *ngIf="showQrCode">
    <h2 class="station-title" style="text-align: center; justify-content: center;">
      {{ data.title }} | QR Code
    </h2>
    <div style="display: flex; justify-content: center; align-items: center;">
      <qrcode [qrdata]="getShareLink()" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
  </div>
</div>
