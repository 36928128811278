<div class="app-personal-information">
  <h1 class="account-page-title">Personal information</h1>

  @if (isLoading()) {
    <mat-spinner diameter="40"></mat-spinner>
  } @else {
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)">
      <div class="profile-photo">
        <div class="avatar">
          <mat-icon>person</mat-icon>
          <app-gc-image [src]="userPhoto()" alt="" />
        </div>

        <button type="button" [disabled]="isUpdating()" mat-flat-button (click)="fileInput.click()">Change photo</button>
        <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" hidden>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Display name</mat-label>
        <input matInput formControlName="displayName" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput [value]="userSignal()?.email" disabled />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input type="text" matInput formControlName="country" [matAutocomplete]="auto" />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayCountry"
          (optionSelected)="form.get('country')?.setValue($event.option.value)"
        >
          <mat-option
            *ngFor="let country of filteredCountries()"
            [value]="country.code"
          >
            <span style="display: flex; align-items: center; gap: 8px">
              <img
                [src]="country.flag"
                alt="{{ country.name }}"
                width="20"
                height="15"
              />
              {{ country.name }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="buttons">
        <button [disabled]="isUpdating()" mat-flat-button type="submit">
          Save changes
        </button>
      </div>
    </form>
  }
</div>
