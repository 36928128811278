import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import countries from '@app/shared/data/countries.json';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { UserService } from '@app/services/user.service';
import { AuthService, UserSignal } from '@app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { map, startWith } from 'rxjs';
import { customCountryValidator } from '@app/shared/utils/form';

@Component({
  selector: 'app-personal-information',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    GcImageComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './personal-information.component.html',
  styleUrl: './personal-information.component.scss',
})
export class PersonalInformationComponent implements OnInit {
  form: FormGroup;
  filteredCountries = signal<{ code: string; name: string; flag: string }[]>(
    [],
  );
  userPhoto = signal('');
  userSignal = signal<UserSignal>(null);
  isUpdating = signal<boolean>(false);
  isLoading = signal<boolean>(false);

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.form = this.fb.group({
      displayName: ['', [Validators.required]],
      country: ['', [Validators.required, customCountryValidator(countries.map((country) => country.code))]],
    });

    this.filteredCountries.set(countries);
  }

  ngOnInit(): void {
    this.isLoading.set(true);

    this.authService.getUserObject().subscribe({
      next: (user) => {
        this.userSignal.set(user);
        this.userPhoto.set(this.userService.getUserPhoto(user.uid));

        this.userService.getMetadataById(user.uid).subscribe({
          next: (metadata) => {
            this.form.patchValue({
              displayName: metadata?.displayName || '',
              country: metadata?.country || '',
            });
            this.isLoading.set(false);

            this.form.get('country')?.valueChanges.subscribe((value) => {
              if (value !== metadata?.country) {
                this.filterCountries(value);
              }
            });
          },
          error: (err) => {
            this.form.patchValue({
              displayName: user.displayName || '',
              country: 'us',
            });
            console.error('Error fetching metadata:', err);
            this.isLoading.set(false);
          },
        });
      },
      error: (err) => {
        console.error('Error fetching user object:', err);
        this.isLoading.set(false);
      },
    });
  }

  filterCountries(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredCountries.set(
      countries.filter((option) =>
        option.name.toLowerCase().startsWith(filterValue),
      ),
    );
  }

  displayCountry(countryCode: string): string {
    const country = countries.find((c) => c.code === countryCode);
    return country ? country.name : '';
  }

  onSubmit(event: Event) {
    event.preventDefault();
    if (!this.form.valid) return;

    this.isUpdating.set(true);

    const formData = this.form.value;
    this.userService.updateMetadata(this.userSignal().uid, formData).subscribe({
      next: (user) => {
        this.userSignal.update((prev) => ({
          ...prev,
          ...user,
        }));
        this.snackBar.open('Personal information updated', undefined, {
          duration: 3000,
        });
        this.isUpdating.set(false);
      },
      error: () => {
        this.snackBar.open('Something went wrong', undefined, {
          duration: 3000,
        });
        this.isUpdating.set(false);
      },
    });
  }

  onFileSelected(event: Event): void {
    event.preventDefault();

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (file.size > 1024 * 1024 * 5) {
        this.snackBar.open('Photo size must be less than 5MB', undefined, {
          duration: 3000,
        });
        return;
      }

      const newFile = new File([file], `${crypto.randomUUID()}.png`, {
        type: file.type,
      });

      this.isUpdating.set(true);
      this.userService
        .uploadUserPhoto(this.userSignal().uid, newFile)
        .subscribe({
          next: () => {
            this.snackBar.open('Photo uploaded successfully', undefined, {
              duration: 3000,
            });
            this.userPhoto.set(
              this.userService.getUserPhoto(this.userSignal().uid) +
                `?v=${Math.random()}`,
            );
            this.isUpdating.set(false);
          },
          error: (error) => {
            const errorMessage =
              error?.error?.errors?.[0]?.errorMessage ?? 'Something went wrong';
            this.snackBar.open(errorMessage, undefined, {
              duration: 3000,
            });
            this.isUpdating.set(false);
          },
        });
    }
  }
}
