import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from "@app/services/auth.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { SocialLoginComponent } from "@app/components/auth/social-login/social-login.component";
import { FirebaseError } from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegisterLoginComponent } from '../register-login/register-login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { catchError, finalize, of } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule, SocialLoginComponent, ReactiveFormsModule, MatProgressSpinnerModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  fb = inject(FormBuilder)
  authService = inject(AuthService)
  loginForm: FormGroup;
  dialogRef = inject(MatDialogRef<RegisterLoginComponent>);
  isLoading = signal(false);

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      this.isLoading.set(true);

      const { email, password } = this.loginForm.value;
      this.authService.login(email, password)
        .pipe(
          catchError((error: FirebaseError) => {
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
              this.snackBar.open('Email or password is invalid', undefined, { duration: 3000 });
            } else {
              return of(null);
            }
            throw error;
          }),
          finalize(() => {
            this.isLoading.set(false);
          }),
        )
        .subscribe(() => {
          this.snackBar.open('User is logged in', undefined, { duration: 3000 });
          this.dialogRef.close();
        });
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.login();
  }

  openSignUpForm() {
    this.dialogRef.close();
    this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'register',
      },
    });
  }
}
