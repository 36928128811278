import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { RegisterLoginComponent } from '../auth/register-login/register-login.component';
import { AuthService, UserSignal } from '@app/services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { GcImageComponent } from '../gc-image/gc-image.component';
import { UserService } from '@app/services/user.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatListModule, MatNavList } from '@angular/material/list';

@Component({
  selector: 'app-user-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    GcImageComponent,
    RouterLink,
    MatMenuModule,
    MatNavList,
    MatListModule,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './user-dropdown.component.html',
  styleUrl: './user-dropdown.component.scss',
})
export class UserDropdownComponent implements OnInit {
  user = signal<UserSignal>(undefined);
  getUserPhoto = null;
  @Input() dropdownStyle: 'dropdown' | 'mat-nav-item' = 'dropdown';
  nestedOpen = signal(false);

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.userSignal;
    this.getUserPhoto = this.userService.getUserPhoto
  }

  openLoginDialog() {
    this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'login',
      },
    });
  }

  openVerifyDialog() {
    this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'verify',
      },
    });
  }

  logout() {
    this.authService.logout().then(() => {
      // TODO: Fix the issue with multiple requests
      // for now, doing a hard refresh
      // this.router.navigate(['/'], { replaceUrl: true });
      window.location.replace('/');
    });
  }

  toggleNested() {
    this.nestedOpen.set(!this.nestedOpen());
  }
}
