import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RewardDialogComponent } from '../profile/reward-dialog/reward-dialog.component';
import { EventInterface } from '@app/services/rewards.interface';

@Component({
  selector: 'app-ndew-popup',
  standalone: true,
  imports: [],
  templateUrl: './ndew-popup.component.html',
  styleUrl: './ndew-popup.component.scss'
})
export class NdewPopupComponent {
  @Input() event: EventInterface;

  constructor(private dialog: MatDialog) { }

  openDialog() {
    this.dialog.open(RewardDialogComponent, {
      width: '700px',
      maxWidth: '700px',
      data: this.event,
    });
  }
}
