import { Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from "@app/services/auth.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import { SocialLoginComponent } from "@app/components/auth/social-login/social-login.component";
import { FirebaseError } from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { RegisterLoginComponent } from '../register-login/register-login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { finalize } from 'rxjs';
import { customPasswordValidator } from '@app/shared/utils/form';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatButtonModule, SocialLoginComponent, ReactiveFormsModule, MatProgressSpinnerModule],
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  fb = inject(FormBuilder)
  authService = inject(AuthService)
  registerForm: FormGroup;
  dialogRef = inject(MatDialogRef<RegisterLoginComponent>);
  isLoading = signal(false);

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      displayName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), customPasswordValidator()]]
    });
  }

  register(): void {
    if (this.registerForm.valid) {
      this.isLoading.set(true);

      const { email, displayName, password } = this.registerForm.value;
      this.authService.register(email, displayName, password)
        .pipe(
          finalize(() => {
            this.isLoading.set(false);
          })
        )
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.dialog.open(RegisterLoginComponent, {
              maxWidth: '1000px',
              data: {
                type: 'verify',
              },
            });
          },
          error: (error: FirebaseError) => {
            this.isLoading.set(false);
            if (error.code === 'auth/email-already-in-use') {
              this.snackBar.open('Email already in use', undefined, { duration: 3000 });
            } else {
              this.snackBar.open('Something went wrong', undefined, { duration: 3000 });
            }
          },
        });
    }
  }

  onRegister(event: Event) {
    event.preventDefault();
    this.register();
  }

  openSignInForm() {
    this.dialogRef.close();
    this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'login',
      },
    });
  }
}
