<div class="mat-elevation-z2" style="margin-top: 20px;">
  <div style="overflow: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="background-color: transparent">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? selectAll() : null" [checked]="isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleSelection(element) : null" [checked]="selection.isSelected(element)"></mat-checkbox>
        </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.location }}</td>
      </ng-container>

      <!-- Session Time Column -->
      <ng-container matColumnDef="sessionTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Session Time</th>
        <td mat-cell *matCellDef="let element">{{ element.sessionTime }}</td>
      </ng-container>

      <!-- Delivered Column -->
      <ng-container matColumnDef="delivered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered</th>
        <td mat-cell *matCellDef="let element">{{ element.delivered }}</td>
      </ng-container>

      <!-- Charging Cost Column -->
      <ng-container matColumnDef="chargingCost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charging Cost</th>
        <td mat-cell *matCellDef="let element">{{ element.chargingCost }}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">{{ element.date }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons style="background-color: transparent"></mat-paginator>
</div>
