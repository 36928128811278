import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';
import {
  Charger,
  ConnectorChargingList,
  ConnectorWaitingList,
} from '@app/services/station.interface';
import { ConnectorData } from '../connectors-tab.component';
import { StationService } from '@app/services/station.service';
import { UserService } from '@app/services/user.service';
import { MatIconModule } from '@angular/material/icon';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { PublicMetadataInterface } from '@app/services/user.interface';
import { MatButtonModule } from '@angular/material/button';
import { getDefaultVehicle } from '@app/shared/utils/station';
import { MatDialog } from '@angular/material/dialog';
import { StartConnectorDialogComponent } from '@app/components/charger/start-connector-dialog/start-connector-dialog.component';
import { AuthService } from '@app/services/auth.service';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-connector-item',
  standalone: true,
  imports: [CommonModule, MatIconModule, GcImageComponent, MatButtonModule],
  templateUrl: './connector-item.component.html',
  styleUrl: './connector-item.component.scss',
})
export class ConnectorItemComponent implements OnInit {
  @Input() connector: ConnectorData;
  @Input() charger: Charger;
  waitingListOpen = signal(false);
  chargingListOpen = signal(false);
  isLoading = signal(false);
  getDefaultVehicle = getDefaultVehicle;

  // Convert activityList to a signal
  activityList = signal<{
    waiting: (ConnectorWaitingList & { user?: PublicMetadataInterface })[];
    charging: (ConnectorChargingList & { user?: PublicMetadataInterface })[];
  }>({
    waiting: [],
    charging: [],
  });
  buttonStatus = signal({
    start: false,
    wait: false,
    endCharging: false,
    endWaiting: false,
  });

  getAuthorPhoto = null;
  user = null;

  constructor(private stationService: StationService, private userService: UserService, private dialog: MatDialog, private authService: AuthService, private snackBar: MatSnackBar) {}

  async ngOnInit() {
    this.getAuthorPhoto = this.userService.getUserPhoto;

    await this.loadChargingList();
    await this.loadWaitingList();
    this.refreshButtonStatus();
  }

  refreshButtonStatus() {
    if (!this.authService.isAuthenticated()) {
      this.buttonStatus.set({
        start: false,
        wait: false,
        endCharging: false,
        endWaiting: false,
      });
      return;
    }

    const userId = this.authService.userSignal()?.uid;
    this.buttonStatus.update(() => ({
      start: !this.activityList().charging.length,
      wait: (!!this.activityList().charging?.length &&
        !this.activityList().waiting?.find((w) => w.userId === userId) &&
        !this.activityList().charging?.find((w) => w.userId === userId)),
      endCharging: !!this.activityList().charging?.length && !!this.activityList().charging?.find((w) => w.userId === userId),
      endWaiting: !!this.activityList().waiting?.find((w) => w.userId === userId),
    }));
  }

  async loadChargingList() {
    const data = await firstValueFrom(this.stationService.getConnectorChargingList(this.connector.id));
    const finalData = [];

    for (const activity of data) {
      const user = await firstValueFrom(this.userService.getMetadataById(activity.userId));

      finalData.push({
        ...activity,
        user,
      });
    }

    this.activityList.update((current) => ({
      ...current,
      charging: finalData,
    }));
  }

  async loadWaitingList() {
    const data = await firstValueFrom(this.stationService.getConnectorWaitingList(this.connector.id));
    const finalData = [];

    for (const activity of data) {
      const user = await firstValueFrom(this.userService.getMetadataById(activity.userId));

      finalData.push({
        ...activity,
        user,
      });
    }

    this.activityList.update((current) => ({
      ...current,
      waiting: finalData,
    }));
  }

  toggleWaitingList() {
    this.waitingListOpen.set(!this.waitingListOpen());
  }

  toggleChargingList() {
    this.chargingListOpen.set(!this.chargingListOpen());
  }

  startActivity(type: "START" | "WAIT" = "START") {
    const dialogRef = this.dialog.open(StartConnectorDialogComponent, {
      width: '500px',
      data: {
        stationId: this.charger.stationId,
        chargerConnectorId: this.connector.id,
        type: type,
      }
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (type === "START") {
        await this.loadChargingList();
      } else {
        await this.loadWaitingList();
      }
      this.refreshButtonStatus();
    });
  }

  async endActivity(type: "START" | "WAIT" = "START") {
    this.isLoading.set(true);

    const activity = type === "START" ? this.activityList().charging?.find((w) => w.userId === this.authService.userSignal()?.uid) : this.activityList().waiting?.find((w) => w.userId === this.authService.userSignal()?.uid);

    await firstValueFrom(this.stationService.createConnectorActivity({
      userId: this.authService.userSignal()?.uid,
      userName: this.authService.userSignal()?.displayName,
      stationId: this.charger.stationId,
      chargerConnectorId: this.connector.id,
      activityType: type === "START" ? "END_START" : "END_WAIT",
      activityId: activity.activityId,
      vehicleModel: activity.vehicleModel,
    }));

    this.snackBar.open("Your session ended successfully", undefined, { duration: 3000 });
    this.isLoading.set(false);

    await this.loadChargingList();
    await this.loadWaitingList();
    this.refreshButtonStatus();
  }
}
