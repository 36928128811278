import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { from, switchMap } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.startsWith('https://places.googleapis.com')) {
    return next(req);
  }

  const authToken = inject(AuthService).getIdToken();
  return from(authToken).pipe(
    switchMap((authToken) => {
      const newReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`),
      });
      return next(newReq);
    }),
  );
};
