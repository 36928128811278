import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ServiceItemComponent } from "@app/components/help/service-item/service-item.component";
import { Place } from "@app/services/help.interface";
import { HelpService } from "@app/services/help.service";
import { getUserCoordinates } from "@app/shared/utils/location";

@Component({
  selector: "app-emergency-service",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule, MatCardModule, ServiceItemComponent],
  templateUrl: "./emergency-service.component.html",
  styleUrl: "./emergency-service.component.scss",
})
export class EmergencyServiceComponent implements OnInit {
  data: Place[] = [];
  status: 'loading' | 'loaded' | 'error' = 'loading';

  constructor(
    private helpService: HelpService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.status = 'loading';
    getUserCoordinates().then((coordinates) => {
      this.helpService
        .getPlaces({
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radius: 10_000,
          keywords: ["car_repair"], // search for towing services
        })
        .subscribe((res) => {
          this.data = res.places;
          this.status = 'loaded';
        });
    }).catch(() => {
      this.status = 'error';
      this.snackBar.open(
        "GitCharger does not have permission to use your location.",
        undefined,
        {
          duration: 3000,
        },
      );
    });
  }

  askForLocation() {
    this.loadData();
  }

}
