<div class="app-navigation-card {{ searchControlService.isNavigationCollapsed ? 'collapsed' : 'expanded' }}" [@slideAnimation]="searchControlService.isNavigationCollapsed ? 'collapsed' : 'expanded'">
  <button class="collapse-button" (click)="togglePanel()">
    <mat-icon>{{ searchControlService.isNavigationCollapsed ? 'arrow_right' : 'arrow_left' }}</mat-icon>
  </button>

  <div class="navigation-content-container">
    <ngx-simplebar [options]="{ autoHide: true }" style="height: 100%">
      <mat-toolbar>
        <button mat-icon-button aria-label="Back button" (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>

        <span>Navigation</span>
      </mat-toolbar>

      <div class="navigation-content">
        <div class="loading-container" *ngIf="isLoading()">
          <mat-spinner diameter="40" class="loading-spinner"></mat-spinner>
        </div>

        <form
          class="location-form"
          cdkDropList
          [cdkDropListData]="waypoints()"
          (cdkDropListDropped)="drop($event)"
        >
          @for (waypoint of waypoints(); track waypoint) {
            <div class="location-item" cdkDrag [cdkDragData]="waypoint">
              <mat-form-field appearance="outline">
                <button mat-icon-button cdkDragHandle>
                  <mat-icon>drag_indicator</mat-icon>
                </button>

                <input
                  matInput
                  [value]="waypoint.name"
                  (input)="updateWaypoint($index, $event)"
                  [matAutocomplete]="auto"
                  #autoFocus
                  [style.color]="waypoint.name === 'Your location' ? '#0097e9' : ''"
                  (click)="clearWaypoint($index, $event)"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="addressSelected($index, $event.option._getHostElement().dataset)"
                >
                  <mat-option
                    *ngFor="let option of waypoint.searchResults"
                    [attr.data-value]="convertJSON(option)"
                    [value]="option.address.formattedAddress"
                  >
                    {{ option.address.formattedAddress }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <button *ngIf="$first" mat-icon-button (click)="addWaypoint()">
                <mat-icon>add</mat-icon>
              </button>
              <button
                *ngIf="!$first && waypoints().length > 2"
                mat-icon-button
                (click)="removeWaypoint($index)"
              >
                <mat-icon>close</mat-icon>
              </button>
              <span
                *ngIf="!$first && waypoints().length <= 2"
                style="display: block; width: 40px; height: 40px; flex-shrink: 0;"
              >
              </span>
            </div>

            <div class="location-item" cdkDrag [cdkDragData]="waypoint" *ngFor="let station of waypoint?.stations">
              <mat-form-field appearance="outline">
                <button mat-icon-button cdkDragHandle>
                  <mat-icon>drag_indicator</mat-icon>
                </button>

                <input
                  matInput
                  [value]="station.name"
                  [matAutocomplete]="auto"
                  disabled
                />
              </mat-form-field>

              <button
                mat-icon-button
                (click)="removeWaypointStation($index, station.id)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          }
        </form>

        <mat-divider></mat-divider>

        <div *ngIf="routeData()" class="route-details">
          <p class="description">
            <span>
              <mat-icon style="float: left; clear: both; margin-right: 8px;">directions</mat-icon>
              <span>
                {{ routeData()?.description }}
              </span>
            </span>
          </p>

          <mat-divider></mat-divider>
        </div>

        <div *ngIf="routeData()" class="waypoint-stepper-container">
          <div class="waypoint-stepper">
            @for (step of waypoints(); track step) {
              <div class="stepper-item" *ngIf="step.placeId">
                <h4 class="name">{{ step.name }}</h4>
                <!-- <p class="address">4517 Washington Ave. Manchester, Kentucky 39495</p> -->
              </div>

              <a class="stepper-item station" *ngIf="step.station" routerLink="/station/{{ step.station.id }}">
                <h4 class="name">{{ step.station.name }}</h4>
                <p class="address">{{ prettyAddress(step.station.locationAddress, step.station.locationCity, step.station.locationState) }}</p>
              </a>
            }
          </div>
        </div>
      </div>
    </ngx-simplebar>
  </div>
</div>
