import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RewardDialogComponent } from '@app/components/profile/reward-dialog/reward-dialog.component';
import { AuthService } from '@app/services/auth.service';
import { RewardInterface } from '@app/services/rewards.interface';
import { RewardsService } from '@app/services/rewards.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-rewards',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './rewards.component.html',
  styleUrl: './rewards.component.scss',
})
export class RewardsComponent implements OnInit {
  private dialog = inject(MatDialog);
  rewards = signal<
  RewardInterface[]
  >(undefined);

  constructor(
    private rewardsService: RewardsService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.rewardsService
      .getRewards(this.authService.userSignal().uid)
      .subscribe((rewards) => {
        this.rewards.set(rewards);
      });
  }

  getRewardName(reward: RewardInterface): string {
    if (reward.info?.title) return reward.info.title;

    const name = reward.rewardType?.split('_')?.join(' ')?.toLowerCase();
    return name?.charAt(0).toUpperCase() + name?.slice(1);
  }

  getRewardDate(date: string): string {
    return dayjs(date).format('D MMM, YYYY');
  }

  openRewardPopup(reward: RewardInterface): void {
    if (!reward.info?.id) return;

    this.dialog.open(RewardDialogComponent, {
      width: '700px',
      maxWidth: '700px',
      data: reward.info,
    });
  }
}
