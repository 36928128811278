import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';
import { GcImageComponent } from '@app/components/gc-image/gc-image.component';
import { ReportDialogComponent } from '@app/components/report-dialog/report-dialog.component';
import { AuthService } from '@app/services/auth.service';
import { CategoryType } from '@app/services/feedback.interface';
import { StationReview } from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { UserService } from '@app/services/user.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-review-item',
  standalone: true,
  imports: [CommonModule, MatIcon, GcImageComponent, RouterLink, MatMenuModule, MatButtonModule],
  templateUrl: './review-item.component.html',
  styleUrl: './review-item.component.scss',
})
export class ReviewItemComponent implements OnInit {
  @Input() review: StationReview = null;
  @Output() reviewDeleted = new EventEmitter<string>();
  showFullText = signal(false);
  reviewDescription = signal('');
  defaultLength = 250;
  getAuthorPhoto = null;
  isLiked = signal(false);
  userSignal = inject(AuthService).userSignal;

  constructor(
    private userService: UserService,
    public authService: AuthService,
    private stationService: StationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.getAuthorPhoto = this.userService.getUserPhoto;
    this.isLiked.set(this.review.loggedInUserRating);

    this.reviewDescription.set(
      this.review.description.length > this.defaultLength
        ? this.review.description.slice(0, this.defaultLength) + '...'
        : this.review.description,
    );
  }

  toggleFullText() {
    this.showFullText.set(!this.showFullText());

    if (this.showFullText()) {
      this.reviewDescription.set(this.review.description);
    } else {
      this.reviewDescription.set(
        this.review.description.length > this.defaultLength
          ? this.review.description.slice(0, this.defaultLength) + '...'
          : this.review.description,
      );
    }
  }

  formatDate(date: string | Date) {
    return dayjs(date.toString().split('T')[0]).format('D MMM, YYYY');
  }

  toggleHelpful() {
    this.isLiked.set(!this.isLiked());

    if (this.isLiked()) {
      this.review.totalLikes++;
    } else {
      this.review.totalLikes--;
    }

    this.stationService.changeReviewLike({
      liked: this.isLiked(),
      commentId: this.review.commentId,
      userId: this.authService.userSignal().uid,
    }).subscribe();
  }

  delete() {
    if (this.userSignal()?.uid !== this.review.userId) return;

    this.stationService.deleteReview(this.review.commentId).subscribe(() => {
      this.reviewDeleted.emit(this.review.commentId);
      this.snackBar.open('Review deleted', undefined, { duration: 3000 });
    });
  }

  openReportDialog() {
    this.dialog.open(ReportDialogComponent, {
      width: '500px',
      data: {
        categoryType: CategoryType.FEEDBACK_COMMENT,
        categoryId: this.review.commentId,
      },
    });
  }
}
