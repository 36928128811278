<div class="app-wallet">
 <div class="dashboard-header">
    <h1 class="account-page-title">Wallet</h1>
    <button mat-flat-button (click)="openAddDialog()">
      <mat-icon>add</mat-icon>
      Add New
    </button>
  </div>

  <div class="wallet-cards">
    <div class="credit-card" *ngFor="let wallet of wallets">
      <div class="credit-card-content">
        <div class="logo">
          <img src="/assets/cards/{{ wallet.cardType }}.svg" alt="{{ wallet.cardType }}" />
        </div>
        <div class="content">
          <div class="number">{{ cardNames[wallet.cardType] }} ending in {{ wallet.cardNumber }}</div>
          <div class="details">
            <div class="card-holder">
              <div class="label">Card Holder</div>
              <div>{{ wallet.cardHolderName }}</div>
            </div>
            <div class="expiry">
              <div class="label">Expires</div>
              <div>{{ wallet.expiryDate }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="credit-card-actions">
        <div class="default" *ngIf="wallet.isDefault">
          Default
        </div>
        <button class="set-as-default" *ngIf="!wallet.isDefault" (click)="setAsDefault(wallet.id)" [disabled]="isLoading()">
          Set as Default
        </button>
        <button class="delete-button" (click)="deleteWallet(wallet.id)" [disabled]="isLoading()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <app-wallet-table></app-wallet-table>
</div>
