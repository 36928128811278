import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  Charger,
  ChargerConnector,
  ConnectorType,
  Station,
} from '@app/services/station.interface';
import { StationService } from '@app/services/station.service';
import { CONNECTOR_ICONS } from '@app/shared/utils/consts';
import { FiltersBottomSheetComponent } from './filters-bottom-sheet/filters-bottom-sheet.component';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

export type ConnectorData = ChargerConnector & {
  connectorType: ConnectorType;
  icon?: string;
};

type ChargerData = Charger & {
  connectors: ConnectorData[];
};
import { firstValueFrom } from 'rxjs';
import { ConnectorItemComponent } from './connector-item/connector-item.component';

@Component({
  selector: 'app-connectors-tab',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    ConnectorItemComponent,
  ],
  templateUrl: './connectors-tab.component.html',
  styleUrl: './connectors-tab.component.scss',
})
export class ConnectorsTabComponent implements OnInit {
  @Input() station: Station = null;
  private initialChargers: ChargerData[] = [];
  filteredChargers: ChargerData[] = [];
  _filtersDialog = inject(MatDialog);
  chargers = signal<ChargerData[]>([]);
  searchTerm = '';

  constructor(
    private stationService: StationService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    const apiConnectorTypes = await firstValueFrom(
      this.stationService.getConnectorTypes(),
    );

    this.initialChargers = this.station?.chargers?.map((charger) => {
      return {
        ...charger,
        connectors: charger.connectors.map((connector) => {
          const connectorType = apiConnectorTypes.find(
            (c) => c.connectorTypeId === connector.connectorTypeId
          );
          return {
            ...connector,
            connectorType,
            icon:
              CONNECTOR_ICONS[
                connectorType?.name?.replace(/[^a-zA-Z0-9]/g, '')
              ] ?? CONNECTOR_ICONS[''],
          };
        }),
      };
    });

    this.route.queryParams.subscribe((params) => {
      this.handleFilters(params);
      this.searchChargers();
    });
  }

  handleFilters(params) {
    const chargers = [];
    const connectorStatus = params['connector_status'];

    if (connectorStatus) {
      // Filter chargers
      this.initialChargers?.map((charger) => {
        const connectors = [];
        charger.connectors?.map((connector) => {
          if (connectorStatus.includes(connector.status)) {
            connectors.push(connector);
          }
        });

        if (!connectors.length) {
          return null;
        }

        chargers.push({
          ...charger,
          connectors,
        });
      }) as ChargerData[];
    } else {
      chargers.push(...this.initialChargers);
    }

    this.filteredChargers = chargers;
  }

  searchChargers() {
    if (!this.searchTerm?.trim()) {
      this.chargers.set(this.filteredChargers);
      return;
    }
    this.chargers.set(
      this.filteredChargers
        .map((charger: ChargerData) => {
          return {
            ...charger,
            connectors: charger.connectors.filter(
              (connector: ConnectorData) =>
                charger.name
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase()) ||
                charger.networks.name
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase()) ||
                connector.connectorType?.name
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase()),
            ),
          } as ChargerData;
        })
        .filter((c) => c.connectors.length > 0),
    );
  }

  openFiltersBottomSheet(): void {
    this._filtersDialog.open(FiltersBottomSheetComponent);
  }
}
