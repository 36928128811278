import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PublicMetadataInterface, Wallet } from './user.interface';
import { User } from './model/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getMetadataById(id: string) {
    return this.http.get<PublicMetadataInterface>(
      `${environment.api.user}/users/metadata/${id}`,
    );
  }

  getUserById(id: string) {
    return this.http.get<User>(`${environment.api.user}/users/${id}`);
  }

  updateMetadata(id: string, metadata: PublicMetadataInterface) {
    return this.http.post<PublicMetadataInterface>(
      `${environment.api.user}/users/metadata/${id}`,
      metadata,
    );
  }

  getUserPhoto(userId: string) {
    return `${environment.api.file}/files/profile/${userId}`;
  }

  uploadUserPhoto(userId: string, file: File) {
    const formData = new FormData();

    // Append the File object directly to formData
    formData.append('fileUpload', file);

    console.log("[ImageUploadService] uploadImage formData:", formData);

    // Return the POST request as an observable
    return this.http.post(`${environment.api.file}/files/profile/${userId}`, formData);
  }

  addWallet(userId: string, wallet: {
    cardNumber: string,
    cardHolderName: string,
    expiryDate: string,
    cardType: string,
    isDefault?: boolean,
    externalCardId: string,
  }) {
    return this.http.post(`${environment.api.user}/payments/cards/${userId}`, wallet);
  }

  getWallets(userId: string) {
    return this.http.get<Wallet[]>(`${environment.api.user}/payments/cards/${userId}`);
  };

  selectDefaultCard(userId: string, cardId: string) {
    return this.http.post(`${environment.api.user}/payments/cards/${userId}/${cardId}/default`, {});
  }

  deleteCard(userId: string, cardId: string) {
    return this.http.delete(`${environment.api.user}/payments/cards/${userId}/${cardId}`);
  }
}
