import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { SkeletonComponent } from "@app/components/skeleton/skeleton.component";
import { SearchControlService } from "@app/pages/map/controls/services/search-control.service";
import { Place } from "@app/services/help.interface";
import { SearchService } from "@app/services/search.service";
import { convertSeconds } from "@app/shared/utils";
import { getUserCoordinates } from "@app/shared/utils/location";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-service-item",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatCardModule, SkeletonComponent],
  templateUrl: "./service-item.component.html",
  styleUrl: "./service-item.component.scss",
})
export class ServiceItemComponent implements OnInit {
  @Input() place: Place;
  distance = null;

  constructor(
    private searchControlService: SearchControlService,
    private router: Router,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.getDistance();
  }

  openDirection() {
    this.searchControlService.autoFillDestination.set(
      `place_id/${this.place.id}`,
    );
    this.router.navigate(["/navigation"]);
  }

  async getDistance() {
    const userLocation = await getUserCoordinates();
    const data = await firstValueFrom(this.searchService.route({
      origin: {
        location: {
          latLng: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
        },
      },
      intermediates: [],
      destination: {
        place: {
          id: this.place.id,
        },
      },
    }));

    this.distance = {
      duration: convertSeconds(data.routes[0].duration),
      mi: (data.routes[0].distanceMeters * 0.000621371).toFixed(1), // convert to miles
    };
  }
}
