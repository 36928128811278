import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventInterface, RewardInterface } from './rewards.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RewardsService {
  constructor(private http: HttpClient) {}

  getRewards(userId: string) {
    return this.http.get<RewardInterface[]>(
      `${environment.api.rewards}/v2/rewards/${userId}`,
    );
  }

  getEvents() {
    return this.http.get<EventInterface[]>(
      `${environment.api.rewards}/v1/events`,
    );
  }

  assignReward(eventId: number, userId: string) {
    return this.http.post(
      `${environment.api.rewards}/v1/event-contribution`,
      {
        userId,
        eventId,
      }
    );
  }
}
