import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElements,
} from '@stripe/stripe-js';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-wallet-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './add-wallet-dialog.component.html',
  styleUrls: ['./add-wallet-dialog.component.scss'],
})
export class AddWalletDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<AddWalletDialogComponent>);

  private _formBuilder = inject(FormBuilder);
  formGroup = this._formBuilder.group({
    name: ['', Validators.required],
  });
  isLoading = signal(false);

  private stripe: Stripe | null = null;
  private elements: StripeElements | null = null;
  private cardElement: StripeCardElement | null = null;

  constructor(private userService: UserService, private authService: AuthService, private snackBar: MatSnackBar) {}

  async ngOnInit() {
    this.stripe = await loadStripe(environment.stripe.publishableKey);
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.cardElement = this.elements.create('card');
      this.cardElement.mount('#card-element');
    }
  }

  formatExpiryDate(month: number, year: number) {
    return `${month < 10 ? '0' : ''}${month}/${year.toString().slice(-2)}`;
  }

  async onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    const { name } = this.formGroup.value;

    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        name,
        email: this.authService.userSignal().email,
      },
    });
    if (error) {
      this.snackBar.open(error.message, undefined, {
        duration: 3000,
      });
      return;
    }

    this.isLoading.set(true);

    const wallets = await firstValueFrom(this.userService.getWallets(this.authService.userSignal().uid));

    this.userService.addWallet(this.authService.userSignal().uid, {
      externalCardId: paymentMethod.id,
      cardNumber: paymentMethod.card.last4,
      cardHolderName: name,
      expiryDate: this.formatExpiryDate(paymentMethod.card.exp_month, paymentMethod.card.exp_year),
      cardType: paymentMethod.card.brand,
      isDefault: wallets?.length === 0,
    }).subscribe(() => {
      this.snackBar.open('Wallet added successfully', undefined, {
        duration: 3000,
      });
      this.isLoading.set(false);
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
