import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  Bookmark,
  ConnectorChargingList,
  ConnectorType,
  ConnectorWaitingList,
  Station,
  StationImage,
  StationReview,
} from './station.interface';

@Injectable({
  providedIn: 'root',
})
export class StationService {
  constructor(private http: HttpClient) {}

  public getStationById([...id]: (string | number)[]) {
    const query = `query StationsInDistance {
        stationById(id: [${id}]) {
          id
          name
          description
          locationAddress
          locationAddress2
          locationCity
          locationState
          locationCountry
          locationPostal
          accessType
          locationType {
            id
            name
            tag
            updatedBy
          }
          location {
              type
              coordinates
          }
          phone
          email
          website
          verified
          timezone
          updatedBy
          createdTs
          updatedTs
          chargers {
              id
              stationId
              networkId
              name
              description
              status
              isPaid
              updatedBy
              connectors {
                  id
                  status
                  chargerId
                  connectorTypeId
                  chargeKwh
                  chargeVolts
                  powerType
              }
              networks {
                  id
                  name
                  description
                  updatedBy
              }
          }
         hours{
            dayHours
            open24Hours
        }
        stationPricingHours {
          pricingHours
          isPaid
        }
        isBookmarked
        parkingType
        amenities {
          id
          tag
          name
          amenityId
        }
      }
    }`;

    return this.http.post<{ data: { stationById: Station[] } }>(
      environment.api.stations_gql,
      { query },
    );
  }

  public getConnectorTypes() {
    return this.http.get<ConnectorType[]>(
      `${environment.api.station}/connector-type`,
    );
  }

  public getStationImagesById(stationId: string | number, pageToken?: string) {
    return this.http.get<{
      content: StationImage[];
      pageable: {
        number: number;
        size: number;
      };
      nextToken?: string;
    }>(
      `${environment.api.file}/files/station/v2/${stationId}${pageToken ? `?pageToken=${pageToken}` : ''}`,
    );
  }

  public getStationReviewsById(stationId: string | number, page = 0) {
    return this.http.get<{
      data: StationReview[];
      currentPage: number;
      numberOfItemsInCurrentPage: number;
      totalItems: number;
      totalPages: number;
    }>(
      `${environment.api.feedback}/comment/?stationId=${stationId}&page=${page}`,
    );
  }

  public getStationRatings(stationId: string | number) {
    return this.http.get<{
      one: number;
      two: number;
      three: number;
      four: number;
      five: number;
    }>(`${environment.api.feedback}/station/rating/${stationId}`);
  }

  addBookmark(stationId: string | number, userId: string) {
    return this.http.post(
      `${environment.api.station}/bookmark`,
      {
        stationId,
        userId
      },
    );
  }

  deleteBookmark(stationId: string | number) {
    return this.http.delete(
      `${environment.api.station}/bookmark/delete?stationId=${stationId}`,
    );
  }

  getBookmarks(userId: string) {
    return this.http.get<Bookmark[]>(
      `${environment.api.station}/bookmark?userId=${userId}`,
    );
  }

  public getConnectorChargingList(connectorId: string | number) {
    return this.http.get<ConnectorChargingList[]>(
      `${environment.api.station}/connector-activity/charging-list?chargerConnectorId=${connectorId}`,
    );
  }

  public getConnectorWaitingList(connectorId: string | number) {
    return this.http.get<ConnectorWaitingList[]>(
      `${environment.api.station}/connector-activity/waiting-list?chargerConnectorId=${connectorId}`,
    );
  }

  getStationImageUrl(stationId: string | number) {
    return `${environment.api.file}/files/station/single/${stationId}`;
  }

  changeReviewLike(data: { liked: boolean, commentId: string, userId: string }) {
    return this.http.post(`${environment.api.feedback}/commentRating`, {
      ...data
    });
  }

  deleteStationImage(userId: string, blobName: string) {
    return this.http.delete(`${environment.api.file}/files/station/v2/${userId}?blobName=${blobName}`);
  }

  deleteReview(commentId: string) {
    return this.http.delete(`${environment.api.feedback}/comment/${commentId}`);
  }

  createConnectorActivity(data: {
    userId: string,
    userName: string,
    stationId: string | number,
    chargerConnectorId: string | number,
    activityType: "START" | "WAIT" | "END_START" | "END_WAIT",
    activityId?: string;
    vehicleModel?: string;
    duration?: string;
    notes?: string;
  }) {
    const method = data.activityType === "END_START" ? "put" : "post";
    return this.http[method](`${environment.api.station}/connector-activity${method === 'put' ? `?activityId=${data.activityId}` : ''}`, data);
  }
}
