import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { Station } from "@app/services/station.interface";
import { ConnectorListComponent } from "../station-details/connector-list/connector-list.component";
import { SkeletonComponent } from "../skeleton/skeleton.component";
import { prettyAddress } from "@app/shared/utils";
import { StationDetailsService } from "@app/pages/station-details/station-details.service";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-station-preview",
  standalone: true,
  imports: [CommonModule, MatIcon, ConnectorListComponent, SkeletonComponent, MatButtonModule],
  templateUrl: "./station-preview.component.html",
  styleUrl: "./station-preview.component.scss",
})
export class StationPreviewComponent implements OnInit {
  @Input() station: Station;
  @Input() onToggleStation = () => {
    //
  };
  loadingStates: WritableSignal<{
    ratings?: boolean;
  }> = signal({
    ratings: false,
  });
  fullAddress = "";
  ratings: { rating: number; count: number } = null;
  stationChargerStatus = null;

  openHoursText = signal('');

  constructor(private stationDetailsService: StationDetailsService) {}

  async ngOnInit(): Promise<void> {
    this.fullAddress = prettyAddress(
      this.station?.locationAddress,
      this.station?.locationAddress2,
      this.station?.locationCity,
      this.station?.locationState,
    );
    this.stationChargerStatus = this.station?.chargers?.reduce(
      (acc, value) => {
        if (value.status === "AVAILABLE") acc.available += 1;
        if (value.status === "OUT_OF_ORDER") acc.outOfOrder += 1;
        if (value.status === "TO_BE_BUILT") acc.toBeBuilt += 1;
        return acc;
      },
      { available: 0, outOfOrder: 0, toBeBuilt: 0 }
    );
    this.openHoursText.set(this.stationDetailsService.getOpenHoursText(this.station));

    this.loadingStates.update((prev) => ({
      ...prev,
      ratings: true,
    }));
    await this.getRatings().finally(() => {
      this.loadingStates.update((prev) => ({
        ...prev,
        ratings: false,
      }));
    });
  }

  async getRatings() {
    this.ratings = await this.stationDetailsService.getRatings(this.station.id);
  }
}
