<div class="app-station-info">
  <div class="section general-details">
    <h3 class="section-title">General details</h3>

    <ul>
      @if (station?.hours) {
        <li>
          @if (hoursOpenState()) {
            <strong>Open hours</strong>
          } @else {
            <strong>Hours: </strong>
            <span innerHTML="{{ openHoursText() }}"></span>
          }
          <mat-icon
            class="expand-button"
            (click)="hoursOpenState.set(!hoursOpenState())"
            *ngIf="hasDayHoursEntries()"
          >
            {{ hoursOpenState() ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
          </mat-icon>

          <div [style.display]="hoursOpenState() ? 'grid' : 'none'" class="hours">
            @if (station?.hours?.open24Hours) {
              <div *ngFor="let entry of weekdays | keyvalue">
                {{ entry.value }}: <span class="open-24">Open 24 hours</span>
              </div>
            } @else {
              <div *ngFor="let entry of stationHours | keyvalue">
                {{ weekdays[entry.key] }}
                <ul>
                  <li *ngFor="let hour of entry.value">
                    <span *ngIf="hour.open">
                      <strong>Open:</strong>
                      {{ formatTime(hour.open) }}
                    </span>
                    <span *ngIf="hour.close">
                      <strong>Close:</strong>
                      {{ formatTime(hour.close) }}
                    </span>
                  </li>
                </ul>
              </div>
            }
          </div>
        </li>
      }
      @if (station.stationPricingHours) {
        <li>
          @if (costsOpenState()) {
            <strong>Costs</strong>
          } @else {
            <strong>Costs: </strong>
            <span innerHTML="{{ costsText() }}"></span>
          }
          <mat-icon
            class="expand-button"
            (click)="costsOpenState.set(!costsOpenState())"
            *ngIf="hasCostsEntries()"
          >
            {{ costsOpenState() ? "keyboard_arrow_up" : "keyboard_arrow_down" }}
          </mat-icon>

          <div [style.display]="costsOpenState() ? 'grid' : 'none'" class="hours">
            <ng-container
              *ngFor="
              let entry of station.stationPricingHours.pricingHours | keyvalue
              "
            >
              <div *ngIf="entry.value.length > 0">
                {{ weekdays[entry.key] }}
                <ul>
                  <li *ngFor="let hour of entry.value">
                    <span
                      [ngClass]="hour.isPaid ? 'text-red' : 'text-green'"
                    >
                      {{ hour.isPaid ? 'Paid' : 'Free' }}
                    </span>
                    <span>
                      <strong>From:</strong>
                      {{ formatTime(hour.from) }}
                    </span>
                    <span>
                      <strong>To:</strong>
                      {{ formatTime(hour.to) }}
                    </span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </li>
      }
      @if (station.locationType) {
        <li>
          <strong>Location:</strong>
          {{ station.locationType.name }}
        </li>
      }
      @if (connectorStatus) {
        <li>
          <strong>Status:</strong>
          {{ connectorStatus }}
        </li>
      }
      @if (station?.accessType) {
        <li>
          <strong>Access: </strong>
          <span style="text-transform: capitalize">{{
            convertLowerCase(station.accessType)
          }}</span>
        </li>
      }
      @if (station?.parkingType) {
        <li>
          <strong>Parking: </strong>
          <span style="text-transform: capitalize">{{
            convertLowerCase(station.parkingType)
          }}</span>
        </li>
      }
    </ul>
  </div>

  <mat-divider></mat-divider>

  <div class="section available-connectors">
    <h3 class="section-title">Available connectors</h3>

    <app-connector-list [station]="station"></app-connector-list>
  </div>

  @if (station?.amenities) {
    <mat-divider></mat-divider>

    <div class="section">
      <h3 class="section-title">Amenities</h3>

      <app-amenities-list
        [amenities]="displayedAmenities"
        [selected]="true"
      ></app-amenities-list>

      @if (station?.amenities?.length > 6) {
        <button (click)="toggleAmenities()" class="toggle-button">
          {{ showAllAmenities ? "View Less" : "View More" }}
        </button>
      }
    </div>
  }

  @if (station?.description) {
    <mat-divider></mat-divider>

    <div class="section">
      <h3 class="section-title">Description</h3>

      <p class="station-description">{{ station.description }}</p>
    </div>
  }

  <mat-divider></mat-divider>

  <p class="last-modified">Last modified date: {{ lastModifiedDate }}</p>
</div>
