<mat-sidenav-container>
  <mat-sidenav
    mode="side"
    disableClose
    opened
    [style.width]="!showFullNav ? '80px' : '240px'"
  >
    <div class="sidenav">
      <div class="logo-container">
        <img
          [src]="!showFullNav ? '/logo.png' : '/logo-text.svg'"
          class="logo"
          alt="GitCharger"
          priority
        />
        <div class="show-mobile">
          <button mat-icon-button onclick="document.querySelector('mat-sidenav').classList.toggle('opened')">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <div class="nav-links">
        <div class="show-mobile" *ngIf="authService.isAuthenticated()">
          <app-user-dropdown dropdownStyle="mat-nav-item"></app-user-dropdown>
        </div>

        @for (group of links; track group) {
          <mat-nav-list
            [style.margin-top]="$last && !$first ? 'auto' : undefined"
          >
            @for (link of group; track link) {
              @if (link.divider) {
                <mat-divider></mat-divider>
              } @else if (link.onClick) {
                <a
                  href="javascript:void(0)"
                  mat-list-item
                  (click)="link.onClick()"
                  *ngIf="link.loginRequired ? authService.isAuthenticated() : true"
                >
                  <mat-icon matListItemIcon>{{ link.icon }}</mat-icon>
                  <span matListItemTitle>{{ link.title }}</span>
                </a>
              } @else {
                <a
                  mat-list-item
                  [routerLink]="link.path"
                  routerLinkActive="activated"
                  [routerLinkActiveOptions]="{ exact: true }"
                  [target]="link?.target"
                >
                  <mat-icon matListItemIcon>{{ link.icon }}</mat-icon>
                  <span matListItemTitle>{{ link.title }}</span>
                </a>
              }
            }
          </mat-nav-list>
        }
      </div>

      <div class="download-links {{ !showFullNav ? 'one-column' : '' }}">
        <a href="https://play.google.com/store/apps/details?id=com.gitcharger.app" target="_blank">
          <img src="assets/playstore{{ !showFullNav ? '-icon' : '' }}.svg" />
        </a>
        <a href="https://apps.apple.com/us/app/gitcharger/id6450578675" target="_blank">
          <img src="assets/appstore{{ !showFullNav ? '-icon' : '' }}.svg" />
        </a>
      </div>

      <mat-divider></mat-divider>
      <div class="hide-mobile" style="display: flex; justify-content: flex-end; align-items: center;">
        <button mat-icon-button style="margin-top: 24px; margin-left: auto;" (click)="toggleNav()">
          <mat-icon>{{ showFullNav ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}</mat-icon>
        </button>
      </div>

      <div style="margin-top: 1rem" class="show-mobile" *ngIf="!authService.isAuthenticated()">
        <app-user-dropdown></app-user-dropdown>
      </div>

      <!-- <div style="padding: 1rem 0;" class="show-mobile">
        <div class="icon-buttons" style="margin-bottom: .5rem;">
          <button mat-icon-button>
            <mat-icon>bookmark</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>notifications</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </div>

        <div class="login-buttons">
          <button mat-flat-button (click)="openSignUpDialog()">Sign Up</button>
          <button mat-flat-button id="loginButton">Log In</button>
        </div>
      </div> -->
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [style.padding-left]="!showFullNav ? '80px' : '240px'"
    [style.margin-left.px]="0"
  >
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
