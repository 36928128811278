<div class="app-add-wallet-dialog">
  <div class="header">
    <h3>Add new payment method</h3>
    <button mat-icon-button class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="content">
    <form
      [formGroup]="formGroup"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="Enter account holder name"
        />
      </mat-form-field>

      <div id="card-element"></div>

      <div style="display: flex; justify-content: flex-end">
        <button
          [disabled]="isLoading() || formGroup.invalid"
          mat-flat-button
          type="submit"
        >
          <span
            style="display: flex; align-items: center; gap: 8px;"
          >
            <mat-spinner *ngIf="isLoading()" diameter="20"></mat-spinner>
            Add payment method
          </span>
        </button>
      </div>
    </form>
  </div>
</div>
