<div class="overlay-container mat-elevation-z2">
  <mat-divider style="margin: 0"></mat-divider>
  <mat-action-list *ngIf="searchResults().length > 0">
    @for (search of searchResults(); track search) {
      <mat-list-item (click)="onSearchItemClick(search)">
        <!--<mat-icon matListItemIcon>history</mat-icon>-->
        <p>{{search?.address?.formattedAddress}}</p>

        <span *ngIf="search.isStation === true" matListItemMeta><mat-icon svgIcon="gc_station"></mat-icon></span>
        <!--<button matListItemMeta mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>-->
      </mat-list-item>
    }
  </mat-action-list>
  <mat-action-list *ngIf="searchResults().length === 0">
    @for (search of recentSearches(); track search) {
      <mat-list-item (click)="onRecentClick(search)">
        <mat-icon matListItemIcon>history</mat-icon>
        <p style="font-size: 0.9rem">{{search?.address?.formattedAddress}}</p>
        <!--<button matListItemMeta mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>-->
      </mat-list-item>
    }
  </mat-action-list>
</div>
