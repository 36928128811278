<div class="app-profile-ratings app-stations-list profile-page-content">
  <div
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="selector"
    [fromRoot]="true"
    (scrolled)="onScroll()"
  ></div>

  <div class="reviews" *ngIf="reviews()?.length > 0">
    <app-review-item
      *ngFor="let review of reviews()"
      [review]="review"
      (reviewDeleted)="onReviewDelete($event)"
    ></app-review-item>
  </div>

  <div class="no-stations" *ngIf="reviews()?.length === 0">
    <img src="assets/link-expired.svg" />
    <p>No ratings given yet.</p>
  </div>

  <div class="stations-loading" *ngIf="reviews() === undefined">
    <mat-spinner [diameter]="20"></mat-spinner> Loading...
  </div>
</div>
