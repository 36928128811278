import { Injectable } from "@angular/core";
import { Station } from "@app/services/station.interface";
import { StationService } from "@app/services/station.service";
import { formatTime } from "@app/shared/utils";
import { daysOfWeek, WEEKDAYS } from "@app/shared/utils/consts";
import { convertStationPricingHoursToIPricingHours, nextPricingChangeAt } from "@app/shared/utils/station-cost";
import { convertStationHoursToIHours, isStationOpen, nextChangeAt, StationOpenState } from "@app/shared/utils/station-hour";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StationDetailsService {
  constructor(private stationService: StationService) {}

  async getRatings(stationId: string | number) {
    try {
      const ratingsData = await firstValueFrom(
        this.stationService.getStationRatings(stationId),
      );

      const sumOfRatings =
        (1 * ratingsData.one || 0) +
        (2 * ratingsData.two || 0) +
        (3 * ratingsData.three || 0) +
        (4 * ratingsData.four || 0) +
        (5 * ratingsData.five || 0);
      const numberOfRatings =
        (ratingsData.one ?? 0) +
        (ratingsData.two ?? 0) +
        (ratingsData.three ?? 0) +
        (ratingsData.four ?? 0) +
        (ratingsData.five ?? 0);
      const average = sumOfRatings / numberOfRatings;

      return {
        rating: Number(average.toFixed(1)) || 0,
        count: numberOfRatings,
      };
    } catch {
      return { rating: 0, count: 0 };
    }
  }

  getOpenHoursText(station: Station) {
    if (station?.hours?.open24Hours) {
      return `<span class="text-green">Open 24 hours</span>`;
    }

    const convertedStationHours = convertStationHoursToIHours(station.hours);
    const status = isStationOpen(convertedStationHours);
    const nextData = nextChangeAt(convertedStationHours);

    if (status === StationOpenState.OPEN) {
      return `<span class="text-green">Open</span> <span>| Closes ${this.formatDateTime(nextData.day, nextData.close)}</span>`;
    } else if (status === StationOpenState.CLOSE) {
      return `<span class="text-red">Closed</span> <span>| Opens ${this.formatDateTime(nextData.day, nextData.open)}</span>`;
    } else if (status === StationOpenState.OPENING_SOON) {
      return `<span class="text-red">Closed</span> <span>| Opening soon ${formatTime(nextData.open)}</span>`;
    } else if (status === StationOpenState.CLOSING_SOON) {
      return `<span class="text-orange">Open</span> <span>| Closing soon ${formatTime(nextData.close)}</span>`;
    }

    return null;
  }

  getCostsText(station: Station) {
    const convertedStationHours = convertStationPricingHoursToIPricingHours(station.stationPricingHours);
    const nextData = nextPricingChangeAt(convertedStationHours);

    return `
      <span class="${nextData.isPaid ? "text-red" : "text-green"}">${nextData.isPaid ? "Paid" : "Free"}</span>
      <span>|</span>
      <span>${WEEKDAYS[daysOfWeek[nextData.day]]}</span>
      <span>${formatTime(nextData.from)} - ${formatTime(nextData.to)}</span>
    `;
  }

  formatDateTime(dayIndex: number, time: string) {
    return `${WEEKDAYS[daysOfWeek[dayIndex]]} ${formatTime(time)}`;
  }
}
