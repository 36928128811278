<div
  class="search-container"
  cdkOverlayOrigin
  #overlayPosition="cdkOverlayOrigin"
  [ngClass]="{ 'opened mat-elevation-z2': overlayOpen() }"
>
  <input #searchInput placeholder="Search..." (click)="overlayOpen.set(true)" [formControl]="searchControlService.searchFormControl" />
  <div>
    <button mat-icon-button (click)="onSearchClick()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button (click)="searchNavigation()">
      <mat-icon>directions</mat-icon>
    </button>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayPosition"
  [cdkConnectedOverlayOpen]="overlayOpen()"
  (overlayOutsideClick)="overlayOpen.set(false)"
>
  <app-search-overlay></app-search-overlay>
</ng-template>
