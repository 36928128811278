<div class="app-emergency-service">
  <h1 class="help-page-title">Emergency service</h1>

  <div *ngIf="status === 'loading'">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <div *ngIf="status === 'error'" class="error">
    <img src="assets/link-expired.svg" />
    <p>Unable to show you services based on your current location.</p>
    <button mat-flat-button (click)="askForLocation()">Share location</button>
  </div>

  <div class="places" *ngIf="status === 'loaded'">
    <app-service-item *ngFor="let place of data" [place]="place"></app-service-item>
  </div>
</div>
